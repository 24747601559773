.pipeline {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.pipeline .PipelineBG {
  display: flex;
  justify-content: center;
  height: 60vh;
  width: 100%;
  position: relative;
  top: 80px;
  background-image: url("../../Image/Formulation-Development-banner.jpeg");
  /* background-image: url("../../Image/Neutraceuticals.jpeg"); */
  background-size: 100% 60vh;
  background-position: center;
  background-repeat: no-repeat;
}
.pipeline .PipelinePage {
  width: 100%;
}
.pipeline .Discription {
  width: 100%;
}
.pipeline .Discription p {
  font-family: "Montserrat", sans-serif;
  position: relative;
  top: 80px;
  text-align: center;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
  /* border-bottom: 2px dotted #df6c00; */
}
.pipeline .Border_Bottom {
  margin-top: 80px;
  /* position: relative; */
  /* top: 80px; */
}
.pipeline .CDMO {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pipeline .CDMO img {
  /* color: red; */
  height: 500px;
  width: 90%;
}
.pipeline .CDMO .HHeading {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.2rem;
}
.pipeline .PagesCards {
  position: relative;
  /* height: 530px; */
  margin: 1rem 1.5rem;
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(4, 24%);
  column-gap: 1%;
  justify-content: space-between;
}
.pipeline .PagesCards .HHeading {
  position: absolute;
  top: -3.5rem;
  font-size: 1.4rem;
  width: 100%;
}
.pipeline .PagesCards .PageCard1,
.pipeline .PagesCards .PageCard2,
.pipeline .PagesCards .PageCard3,
.pipeline .PagesCards .PageCard4 {
  position: relative;
  padding: 0rem 0rem;
  height: 550px;
}

.pipeline .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.pipeline .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.4);
}
.pipeline .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.pipeline .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.4);
}
.pipeline .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid #0a3f80;
}
.pipeline .PagesCards .PageCard3:hover {
  background-color: rgba(10, 91, 189, 0.4);
}
.pipeline .PagesCards .PageCard4 {
  background-color: rgba(96, 49, 170, 0.2);
  border-bottom: 10px solid #6031aa;
}
.pipeline .PagesCards .PageCard4:hover {
  background-color: rgba(96, 49, 170, 0.4);
}
.pipeline .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.pipeline .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.pipeline .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
}
.pipeline .PagesCards .PageCard4 .PageCardTitle {
  background-color: rgba(96, 49, 170, 0.6);
}
.pipeline .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem 1.2rem 1.2rem 0.5rem;
  text-align: left;
  font-size: 1rem;
  font-family: "Montserrat";
}
.pipeline .PagesCards div .PageCardTitleIcon {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 5px;
  top: 15px;
}

.pipeline .PagesCards div .PageDiscriptionShort {
  padding: 0.5rem 1rem;
  height: 380px;
  /* overflow: hidden; */

  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 2.5rem;
}
.pipeline .PagesCards div .PageLink .hover-underline-animation {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 1rem 0rem 1rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.pipeline .DifferentiatedProduct .PipelinePage .DifferentiatedProductTopBG,
.pipeline .InnovativeProduct .PipelinePage .InnovativeProductTopBG,
.pipeline .ComplexGenerics .PipelinePage .ComplexGenericsTopBG,
.pipeline .GenericsProduct .PipelinePage .GenericsProductTopBG {
  display: none;
}
.pipeline .DifferentiatedProduct .PipelinePage .product_List,
.pipeline .InnovativeProduct .PipelinePage .product_List,
.pipeline .ComplexGenerics .PipelinePage .product_List,
.pipeline .GenericsProduct .PipelinePage .product_List {
  font-family: "Montserrat", sans-serif;
}
.pipeline .InnovativeProduct.FullSize,
.pipeline .ComplexGenerics.FullSize,
.pipeline .GenericsProduct.FullSize {
  margin-top: 0 !important;
}
.PipelinePage .ComplexGenericsTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  /* background-image: url("../../Image/ComplexGenericProduct.png"); */
  background-size: 90% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .DifferentiatedProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url("../../Image/pre-formulation-development-api-2048x878.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .InnovativeProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url("../../Image/Innovative\ Product.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
/* .PipelinePage .NutraceuiticalProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url("../../Image/Innovative\ Neutraceuticals.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
} */
.PipelinePage .GenericsProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;
  background-image: url("../../Image/Generic\ Product.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .PreFormulServicesTopBG,
.PipelinePage .FormulDevelopServicesTopBG,
.PipelinePage .TechnicalServicesTopBG,
.PipelinePage .AnalyticalServicesTopBG {
  justify-content: center;
  height: 50vh;
  width: 90%;
  margin: 0 5%;
  position: relative;
  /* background-image: url("../../Image/Oral\ DD1.jpg"); */
  background-size: 100% 60vh;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}
.PipelinePage .Pipeline .HHeading {
  font-family: "Montserrat", sans-serif;
  /* text-align: center; */
  padding-left: 5rem;
  color: rgb(1, 27, 75);
  font-size: 1.6rem;
  margin-bottom: 0rem;
  margin-top: 2rem;
}
/* ?????? */
/* ?????? */
/* ?????? */
.product_List {
  width: 100%;
}
.product_List .HHeading {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  margin: 1rem;
  text-align: left;
  margin-left: 5rem;
}
.product_List .ShortDiscription {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 2.2rem;
  margin: 0.8rem 5rem;
  /* text-align: center; */
}
.Services_List .ShortDiscription {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 2.2rem;
  margin: 2rem 5rem;
  /* text-align: center; */
}
.product_List .ProdDevProgressDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product_List .ProdDevProgressDetails .ProductSection {
  background-color: rgb(248, 248, 248);
  /* background-color: rgb(161, 153, 153); */
  border-radius: 1.5rem;
  padding-top: 2rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  position: relative;
  width: 980px;
}
.product_List .ProdDevProgressDetails .ProductSection .Tbody {
  /* background-color: rgb(70, 161, 48); */
  /* margin-bottom: 1.5rem; */
  padding: 0;
  position: relative;
  left: -140px;
  top: -76px;
  /* display: flex; */
}
.product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
  /* background-color: aqua; */
  position: absolute;
  left: 680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 3rem; */
  font-size: 1.2rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress {
  margin: 0 0.5rem;
}
.product_List .ProdDevProgressDetails .ProductSection .ProductName {
  font-family: "Montserrat", sans-serif;

  color: rgb(15, 60, 126);
  margin-left: 3rem;
  font-size: 1rem;
  width: 440px;
}
.product_List .ProdDevProgressDetails .ProductSection .ProductRLDdev {
  font-family: "Montserrat", sans-serif;

  position: relative;
  left: 0;
  color: rgb(13, 98, 209);
  font-size: 12px;
  font-weight: 700;
  padding: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  margin-left: 3rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60px;
}
.table-row .ProdDevProgress .fas.fa-exclamation-circle {
  align-self: center;
  display: block;
  color: rgb(233, 158, 97);
  background-color: #fff !important;
  border-radius: 50%;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-check-circle {
  align-self: center;
  display: block;
  color: rgb(50, 163, 69);
  border-radius: 50%;
  background-color: #fff !important;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-times-circle {
  align-self: center;
  display: block;
  border-radius: 50%;
  color: rgb(190, 185, 185);
  background-color: #fff;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-sync {
  align-self: center;
  display: block;
  color: rgb(27, 99, 167);
  background-color: rgb(255, 255, 255);
  margin-bottom: 0rem;
  padding: 0.2rem;
  border-radius: 50%;
  animation: 2s linear 0s 10 rotateAtPoint;
  font-size: 1.5rem;
  z-index: 5;
}
.table-row .ProdDevProgress p {
  font-family: "Montserrat", sans-serif;

  align-self: center;
  font-size: 0.6rem;

  width: max-content;
}
.table-row .ProdDevProgress i {
  font-size: 1.5rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress::after {
  content: "";
  position: relative;
  top: -32px;
  transform: translatex(-9%);
  height: 8px;
  width: 80px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress:first-child:after {
  content: "";
  background-color: rgb(192, 185, 185);
  position: relative;
  top: -32px;
  transform: translatex(50%);
  height: 8px;
  width: 48px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress:last-child:after {
  content: "";
  background-color: rgb(192, 185, 185);
  position: relative;
  top: -32px;
  transform: translatex(-22%);
  height: 8px;
  width: 35px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.GreenProLine::after {
  background-color: rgb(113, 197, 127);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.GrayProLine::after {
  background-color: rgb(190, 185, 185);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.OrangeProLine::after {
  background-color: rgb(233, 158, 97);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.BlueProLine::after {
  position: relative;
  background-color: rgb(97, 149, 197);
  margin-bottom: 3px;
}

.OutLicensing_List .OutLicTable {
  font-family: "Montserrat", sans-serif;

  border: 1px solid rgb(128, 128, 128);
  border-radius: 5px;
  border-collapse: collapse;
  z-index: 2;
  margin-top: 0.5rem;
  margin-left: 3rem;
  margin-bottom: 1.5rem;
  width: max-content;
  position: relative;
}
.OutLicensing_List .OutLicTable .OutLicTableHead {
  border-collapse: collapse;
}
.OutLicensing_List .OutLicTable .OutLicTableBody {
  border-collapse: collapse;
}
.OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
  /* background-color: rgb(91, 142, 209); */
  color: rgb(14, 65, 107);
  width: 80px;
  font-size: 14px;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
  border: 1px solid rgb(117, 116, 116);
  border-collapse: collapse;
  font-weight: 700;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue {
  color: rgb(14, 65, 107);
  font-weight: 900;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicRemarkRow {
  color: rgb(14, 65, 107);
  font-weight: 900;
  font-size: 14px;
  width: 100%;
}
.OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicRemarkRow
  .outLicRemarValue {
  border-right: 1px solid rgb(116, 116, 116);
  padding: 0.2rem 0.5rem 0.2rem;
  color: rgb(14, 65, 107);
  font-weight: 900;
  font-size: 14px;
}
/* .OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicRemarkRow
  .outLicRemarValue
  .ProductTableBTN {
  text-decoration: none;
  border-radius: 0.5rem;
  margin-left: 1.2rem;
  background-color: rgb(17, 111, 199);
  padding: 0.12rem 0.6rem 0.15rem;
  color: rgb(250, 250, 250);
  font-size: 14px;
} */
.OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(124, 124, 124);
  color: black;
  color: rgb(14, 65, 107);
  font-weight: 700;
  border-collapse: collapse;
  padding: 0.3rem 0.5rem;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
  padding: 0.2;
  text-align: center;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue.Avaliable {
  color: rgb(35, 182, 60);
}

.OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicTableValue.notAvaliable {
  color: rgb(243, 54, 54);
}
.product_List .ProdDevProgressDetails .ProductSection .OutLicensing_List {
  display: none;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .OutLicensing_List.viewMore {
  display: block;
}
.product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(54, 117, 233);
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-left: 3rem;
  margin-bottom: 0.2rem;
  position: relative;
  /* left: 645px; */
  /* top: 27px; */

  display: block;
}
.product_List .ProdDevProgressDetails .ProductSection .onclickoutlic:hover {
  background-color: rgb(29, 82, 180);
  color: #fff;
  transform: scale(0.98);
  display: block;
}

.OutLicTable .OutLicTableBody .outLicContactUsBTNRow {
  width: 100%;
  margin: 0 auto;
}
.OutLicTable .OutLicTableBody .outLicContactUsBTNRow .outLicContactUsBTN {
  align-self: center;
  padding: 0.3rem;
  font-size: 0;

  border-top: 1px solid rgb(124, 124, 124);
}
.OutLicTable
  .OutLicTableBody
  .outLicContactUsBTNRow
  .outLicContactUsBTN
  .ProductTableBTN {
  margin-left: 38%;
  text-decoration: none;
  color: rgb(250, 250, 250);
  padding: 0.12rem 0.6rem 0.1rem;
  font-size: 14px;
  border-radius: 0.5rem;
  background-color: rgb(17, 111, 199);
}

@media screen and (max-width: 1100px) {
  .pipeline .PipelineBG {
    height: 30vw;
    top: 80px;
    background-size: 100% 30vw;
  }
  .pipeline .Discription p {
    top: 80px;
    line-height: 1.8rem;
    padding: 1rem 2rem;
    font-weight: 400;
    font-size: 1rem;
  }
  .pipeline .CDMO img {
    height: 55vw;
    width: 98%;
  }
  .pipeline .CDMO .HHeading {
    font-size: 1rem;
  }
  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    grid-template-columns: repeat(2, 48%);
    column-gap: 2%;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 400px;
    margin-bottom: 1.5rem;
  }
  .pipeline .PagesCards div .PageCardTitle {
    padding: 1.2rem;
    text-align: left;
    font-size: 1rem;
    font-family: "Montserrat";
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1rem;
    height: 260px;
    font-size: 1rem;
    line-height: 2.2rem;
  }
  /* productcard */
  .PipelinePage .PreFormulServicesTopBG,
  .PipelinePage .FormulDevelopServicesTopBG,
  .PipelinePage .TechnicalServicesTopBG,
  .PipelinePage .AnalyticalServicesTopBG {
    height: 30vw;
    width: 98%;
    margin: 0 1%;
    background-size: 100% 30vw;
  }
  .PipelinePage .Pipeline .HHeading {
    padding-left: 4rem;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .product_List .ShortDiscription {
    font-size: 0.9rem;
    line-height: 2.2rem;
    margin: 1rem 4rem;
  }
  .product_List .HHeading {
    font-size: 1.2rem;
    margin-left: 4rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection {
    border-radius: 1.5rem;
    padding-top: 2rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    position: relative;
    width: 540px;
  }
  .product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
    position: absolute;
    left: 200px;
    top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-left: 3rem;
    margin-bottom: 2.2rem;
    position: relative;
    top: 45px;

    display: block;
  }
  .OutLicensing_List .OutLicTable {
    top: 1.2rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
    width: 60px;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.2rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
  .OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.5rem;
  }
  .OutLicensing_List
    .OutLicTable
    .OutLicTableBody
    .outLicRemarkRow
    .outLicRemarValue {
    padding: 0.2rem 0.5rem 0.2rem;
    font-weight: 600;
    font-size: 10px;
  }
}
@media screen and (max-width: 780px) {
  .outLicTableValue .fa-times {
    color: rgb(243, 54, 54);

    font-size: 12px;
  }
  .pipeline .PipelineBG {
    height: 30vw;
    top: 80px;
    background-size: 100% 30vw;
  }

  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 330px;
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    height: 180px;
  }
  /* productcard */
}
@media screen and (max-width: 550px) {
  .pipeline .PipelineBG {
    top: 50px;
  }
  .pipeline .Discription p {
    top: 50px;
    text-align: justify;
    line-height: 1rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.6rem;
  }
  .pipeline .CDMO .HHeading {
    font-size: 0.9rem;
  }
  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 430px;
    margin-bottom: 1.5rem;
  }
  .pipeline .PagesCards .HHeading {
    top: -4.5rem;
    font-size: 1rem;
    width: 100%;
  }
  .pipeline .PagesCards div .PageCardTitle {
    padding: 1rem;
    font-size: 0.9rem;
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    height: 275px;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  /* productcard */
  .PipelinePage .PreFormulServicesTopBG,
  .PipelinePage .FormulDevelopServicesTopBG,
  .PipelinePage .TechnicalServicesTopBG,
  .PipelinePage .AnalyticalServicesTopBG {
    height: 30vw;
    width: 98%;
    margin: 0 1%;
    background-size: 100% 30vw;
  }
  .PipelinePage .Pipeline .HHeading {
    padding-left: 1.5rem;
    font-size: 1rem;
    margin-top: 0rem;
  }
  .product_List .ShortDiscription {
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin: 1rem 1.5rem;
  }
  .product_List .HHeading {
    font-size: 1rem;
    margin-left: 1.5rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection {
    border-radius: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin: 0 auto;
    margin-bottom: 1.2rem;
    width: 98%;
  }
  .product_List .ProdDevProgressDetails .ProductSection .ProductName {
    margin-left: 1rem;
    font-size: 0.8rem;
    width: 90%;
  }
  .product_List .ProdDevProgressDetails .ProductSection .ProductRLDdev {
    position: relative;
    left: 0;
    font-size: 10px;
    margin-left: 1rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
    position: absolute;
    left: 160px;
    top: 25px;
    font-size: 0.8rem;
  }
  .table-row .ProdDevProgress .fas.fa-exclamation-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-check-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-times-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-sync {
    font-size: 1rem;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress::after {
    top: -22px;
    height: 4px;
    width: 60px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress:first-child:after {
    top: -22px;
    height: 4px;
    width: 36px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress:last-child:after {
    top: -22px;
    height: 4px;
    width: 26px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress {
    width: 40px;
  }
  .table-row .ProdDevProgress p {
    font-size: 0.4rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
    color: #fff;
    padding: 0.1rem 0.2rem;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 2rem;
    position: relative;
    top: 32px;
    font-size: 0.6rem;

    display: block;
  }
  .OutLicensing_List .OutLicTable {
    top: 0.25rem;
    z-index: 2;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
    width: max-content;
    position: relative;
  }
  .OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
    width: 40px;
    font-size: 8px;
    font-weight: 600;
    padding: 0.1rem 0.1rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
  .OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.5rem;
  }
  .OutLicensing_List
    .OutLicTable
    .OutLicTableBody
    .outLicRemarkRow
    .outLicRemarValue {
    padding: 0.2rem 0.5rem 0.2rem;
    font-weight: 600;
    font-size: 10px;
  }
}
