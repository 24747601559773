.navbar {
  background: rgb(255, 255, 255, 0.9);
  background: rgba(255, 248, 244, 0.9);
  height: 80px;
  width: 100vw;
  position: FIXED;
  top: 0;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0.1rem 1rem;
  font-family: "Montserrat", sans-serif;
}
.navbar-logo img {
  margin-left: 20%;
  cursor: pointer;
  height: 75px;
  width: 130px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, max-content);
  grid-gap: 5px;
  list-style: none;
  text-align: center;
  width: 90%;
  justify-content: end;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 60px;
}

.nav-links {
  color: #074468;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links.Active {
  color: rgb(66, 164, 221);
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links:hover {
  color: rgb(66, 164, 221);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  position: relative;
  right: 1rem;
  display: none;
}
@media screen and (max-width: 1100px) {
  .navbar {
    width: 100%;
    font-size: 14px;
    padding: 0.1rem 0.2rem;
  }
  .navbar-logo img {
    margin-left: 15%;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    color: #074468;
    font-weight: 600;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    width: 100%;
    text-align: center;
  }
  .nav-links:hover {
    border-radius: 0;
  }
}

@media screen and (max-width: 780px) {
  .fa-caret-down {
    display: none;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 80vh;

    width: 30%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: #dfdfdf;
    height: 100vh;
    padding-bottom: 60vh;
    /* top: 20vh; */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 11;
  }
  .menu-icon .fa-bars {
    color: #1888ff;
  }

  .fa-times {
    color: #1888ff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}
@media screen and (max-width: 450px) {
  .navbar {
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    /* padding: 0.1rem 0.2rem; */
  }
  .navbar-logo img {
    margin-left: 15%;
    cursor: pointer;
    height: 50px;
    width: 80px;
  }
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #dfdfdf;
    height: 100vh;
    padding-bottom: 40vh;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    font-size: 0.8rem;

    text-align: center;
    padding: 1rem;
    width: 100%;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 11;
  }
  .menu-icon .fa-bars {
    color: #1888ff;
    font-size: 1.5rem;
  }

  .fa-times {
    color: #1888ff;
    font-size: 1.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}
