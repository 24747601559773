.Card_ContainerTechPlat {
  margin: 15px;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.TechSerCard {
  position: relative;
  font-family: "Montserrat", sans-serif;
  height: 450px;
  width: 450px;
  padding: 20px;
  margin: 5px;
  margin-top: 110px;

  background-color: rgb(241, 241, 241);
  border-radius: 8px;
}
.TechSerCard .IconCircle {
  height: 120px;
  width: 120px;
  border-radius: 60px;
  position: absolute;
  top: -60px;
  left: 165px;
  background-color: rgb(180, 180, 180);
  padding: 10px;
}
.TechSerCard .IconCircle p {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: rgb(205, 208, 250);
}
.TechSerCard .CardTitle {
  position: relative;
  text-align: center;
  top: -120px;
  left: -10px;
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  text-shadow: 2px 1px wheat;
  line-height: 2.8rem;
  font-size: 1.6rem;
  font-weight: 900;
}
.TechSerCard .Description {
  line-height: 1.2rem;
  font-size: 1rem;
  font-weight: 900;
  height: fit-content;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -moz-box-orient: vertical;
}
.TechSerCard .SubDescription {
  /* line-height: 1.05rem;
    font-weight: 500;
    font-size: 0.95rem; */
  margin-top: 10px;
  line-height: 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;

  height: fit-content;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -moz-box-orient: vertical;
}
.TechSerCard .PageLink {
  position: absolute;
  bottom: 20px;
  left: -10px;
}
@media screen and (max-width: 1100px) {
  .TechSerCard {
    height: 400px;
    width: 400px;
    padding: 10px;
    margin: 3px;
    margin-top: 90px;
    border-radius: 6px;
  }
  .TechSerCard .IconCircle {
    height: 100px;
    width: 100px;
    border-radius: 60px;
    top: -50px;
    left: 145px;
    padding: 10px;
  }
  .TechSerCard .IconCircle p {
    height: 80px;
    width: 80px;
    border-radius: 40px;
  }
  .TechSerCard .CardTitle {
    position: relative;
    text-align: center;
    top: -100px;
    left: -10px;
    line-height: 2.4rem;
    font-size: 1.6rem;
  }
  .TechSerCard .Description {
    margin-top: 0.4rem;
    line-height: 1.15rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .TechSerCard .SubDescription {
    margin-top: 8px;
    line-height: 1.1rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 450px) {
  .TechSerCard {
    height: 4 50px;
    width: 350px;
    padding: 5px;
    margin: 3px;
    margin-top: 80px;
    border-radius: 6px;
  }
  .TechSerCard .IconCircle {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    top: -40px;
    left: 135px;
  }
  .TechSerCard .IconCircle p {
    height: 60px;
    width: 60px;
  }
  .TechSerCard .CardTitle {
    position: relative;
    text-align: center;
    top: -80px;
    left: 0px;
    line-height: 2.4rem;
    font-size: 1.3rem;
  }
  .TechSerCard .Description {
    margin-top: 0.4rem;
    line-height: 1.15rem;
    font-size: 0.8rem;
    font-weight: 600;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -moz-box-orient: vertical;
  }
  .TechSerCard .SubDescription {
    margin-top: 8px;
    line-height: 1.1rem;
    font-size: 0.8rem;
  }
}
