@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
.navbar {
  background: rgb(255, 255, 255, 0.9);
  background: rgba(255, 248, 244, 0.9);
  height: 80px;
  width: 100vw;
  position: FIXED;
  top: 0;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0.1rem 1rem;
  font-family: "Montserrat", sans-serif;
}
.navbar-logo img {
  margin-left: 20%;
  cursor: pointer;
  height: 75px;
  width: 130px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, max-content);
  grid-gap: 5px;
  list-style: none;
  text-align: center;
  width: 90%;
  justify-content: end;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 60px;
}

.nav-links {
  color: #074468;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links.Active {
  color: rgb(66, 164, 221);
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links:hover {
  color: rgb(66, 164, 221);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  position: relative;
  right: 1rem;
  display: none;
}
@media screen and (max-width: 1100px) {
  .navbar {
    width: 100%;
    font-size: 14px;
    padding: 0.1rem 0.2rem;
  }
  .navbar-logo img {
    margin-left: 15%;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    color: #074468;
    font-weight: 600;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    width: 100%;
    text-align: center;
  }
  .nav-links:hover {
    border-radius: 0;
  }
}

@media screen and (max-width: 780px) {
  .fa-caret-down {
    display: none;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 80vh;

    width: 30%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: #dfdfdf;
    height: 100vh;
    padding-bottom: 60vh;
    /* top: 20vh; */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 11;
  }
  .menu-icon .fa-bars {
    color: #1888ff;
  }

  .fa-times {
    color: #1888ff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}
@media screen and (max-width: 450px) {
  .navbar {
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    /* padding: 0.1rem 0.2rem; */
  }
  .navbar-logo img {
    margin-left: 15%;
    cursor: pointer;
    height: 50px;
    width: 80px;
  }
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #dfdfdf;
    height: 100vh;
    padding-bottom: 40vh;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    font-size: 0.8rem;

    text-align: center;
    padding: 1rem;
    width: 100%;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 11;
  }
  .menu-icon .fa-bars {
    color: #1888ff;
    font-size: 1.5rem;
  }

  .fa-times {
    color: #1888ff;
    font-size: 1.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}

.dropdown-menu {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 60px;
  /* width:  ; */
  min-width: 150px;
  list-style: none;
  text-align: start;
  /* border-radius: 20px; */
  border-top: 3px solid #0756a0;

  background: linear-gradient(180deg, #ffffff 0%, #cacaca 100%);
  /* background: radial-gradient(
    #ffffff 0%,
    #eeeded 30%,
    #f0f0f0 70%,
    #bebdbd 100%
  ); */
}
.dropdown-menu li {
  font-size: 16px;
  color: #126bc1;
  cursor: pointer;
}
.dropdown-menu.checked {
  display: none;
}
.dropdown-link {
  font-weight: 600;
  transition: ease-in;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgb(8, 63, 116);
  padding: 12px 16px;
  border-radius: 10px;
}
.dropdown-link:hover {
  transition: ease-in;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgb(93, 165, 233);
  padding: 12px 16px;
  border-radius: 10px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* .home, */
.aboutUs,
/* .companyOverview, */
.leadershipTeam,
.researchAndDevelop,
.ourPresence,
.pipeline,
.careers,
.contactUs {
  /* font-family: "montse", sans-serif; */
  font-family: "Montserrat", sans-serif;

  display: flex;
  height: unset;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  /* margin-top: 80px; */
  height: max-content;
}
/* home css */
/* .home {
  color: #464646;
  font-size: 2rem;
  display: flex;
  background-color: #fff;
} */

/* /animation/ */
@keyframes rotateAtPoint {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slideInFromRightAndFade {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translatey(-200%);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translatey(200%);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes scaleOnPoint {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

/* home css */

/* About Us css  */

/* About Us css  */

/* Research And Devlopment css  */
.researchAndDev {
  /* background-image: url("./Image/img-2.jpg"); */
  /* background-position: center; */
  /* background-size: cover; */
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.partners {
  /* background-image: url("./Image/img-4.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* .careers {
  background-image: url("./Image/img-7.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

.ourPresence {
  display: flex;
  color: #0f63ff;
  font-size: 2rem;
}
.ourPresence .Our_Presence_BG {
  /* background-image: url("./Image/undraw_Our_solution_re_8yk6.png"); */
  /* background-position: center; */
  background-size: 50vw 50vh;
  background-repeat: no-repeat;
  font-size: 100px;
  height: 60vh;
  width: 50%;
}
/* contact us */

/* contact us */

.FullSize {
  margin-top: 80px !important;
}
.Border_Bottom {
  background-image: url(/static/media/borderline.8b0a9d45.jpg);
  background-size: 100% 18px;
  height: 18px;
  width: 100%;
}

@media screen and (max-width: 780px) {
  .Border_Bottom {
    background-image: url(/static/media/borderline.8b0a9d45.jpg);
    background-size: 100% 10px;
    height: 10px;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .FullSize {
    margin-top: 50px !important;
  }
  .Border_Bottom {
    background-image: url(/static/media/borderline.8b0a9d45.jpg);
    background-size: 100% 5px;
    height: 5px;
    width: 100%;
  }
}
/* tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  visibility: hidden;
  width: 100px;
  background-color: rgb(68, 68, 68);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -50px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(68, 68, 68) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* tooltip */

.home {
  background-image: url("/static/media/Injectible Formulation2F.78773ca8.jpg");
  background-size: 100vw 90vh;
  background-position: top;
  background-repeat: no-repeat;
  margin-top: 80px;
  height: unset;
  width: 100%;
}
.home .Main.heading {
  background: transparent;
  height: 90.2vh;
}
.home .Main.heading .mainTitle .Primery.Heading {
  font-family: "Montserrat", sans-serif;
  /* padding: 1rem; */
  /* padding-left: 2rem; */
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  color: #fff;
  text-shadow: 2px wheat;
  width: 100%;
  height: 100%;
  line-height: 3.8rem;
  font-size: 2.2rem;
  font-weight: 900;
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 {
  text-align: left;
  animation: 1.3s ease-out 0s 1 slideInFromRightAndFade;
  font-family: Arial;
  /* padding-top: 4rem; */
  position: relative;
  top: 50px;
  padding-left: 2rem;
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 .Special_wordDiff {
  color: #fff;
  /* font-style: italic; */
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 .Special_wordInno {
  color: #fff;
  /* font-style: italic; */
}

.home .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}

.home .Discription p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #41403f;
  color: #081e47;
}
.Special_In {
  color: red;
  text-decoration: underline;
}

/* ????? */
.home .WhatWeDo {
  font-family: "Montserrat", sans-serif;

  position: relative;
  top: 5%;
  /* left: 5rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  color: #313131;
  font-size: 1.4rem;
}
.home .WhatWeDo .AboutUs_WhatWeDo {
  padding-left: 6rem;
  margin-top: 2rem;
  padding-bottom: 3rem;
  font-size: 1.8rem;
}

.home .WhatWeDo .iconList {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-row-gap: 10px;
  color: #0f7fff;
  font-size: 1.8rem;
}
.home .WhatWeDo .iconList li img {
  position: relative;
  top: -12%;
  border: 3px solid rgb(226, 223, 223);
  border-radius: 50%;
  height: 250px;
  width: 250px;
}
.home .WhatWeDo .iconList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  list-style: none;
  color: #383838;
  margin-left: 0.6rem;
  margin: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.home .WhatWeDo .iconList li p {
  width: 95%;
  text-align: center;
  color: #383838;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.3rem;
}
.home .WhatWeDo h4 {
  position: relative;
  color: #083981;
  top: -1%;
  font-size: 2.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;
}
.home .PagesCards {
  /* height: 530px; */
  height: 620px;
  margin: 0 2%;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 1%;
  column-gap: 1%;
  justify-content: space-between;
}
.home .PagesCards .PageCard1,
.home .PagesCards .PageCard2,
.home .PagesCards .PageCard3 {
  /* border: 1px solid black; */
  position: relative;
  padding: 0rem 0rem;
  /* height: 500px; */
  height: 550px;
}
.home .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.home .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.3);
}
.home .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.home .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.3);
}
.home .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid rgb(10, 91, 189);
}
.home .PagesCards .PageCard3:hover {
  background-color: rgb(10, 91, 189, 0.3);
}
.home .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem;
  text-align: left;
  font-size: 1.2rem;
  font-family: "Montserrat";
}
.home .PagesCards div .PageCardTitle p {
  width: 85%;
}
.home .PagesCards div .PageCardTitleIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.home .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.home .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.home .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
}
.home .PagesCards div .PageDiscriptionShort {
  padding: 0.5rem 1.2rem;
  height: 410px;
  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 2.4rem;
}
/* .home .PagesCards div a {
  padding: 0.5rem 1.5rem;

  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #0f7fff;
  font-family: "Montserrat";
} */
/* .home .PagesCards div a:hover {
  padding: 0.5rem 1.5rem;

  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #0f7fff;
  font-family: "Montserrat";
} */
.hover-underline-animation {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 2rem 0rem 2rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* responsiveness */
@media screen and (max-width: 1100px) {
  .home .Main.heading {
    background: transparent;
    height: 90vh;
  }
  .home .Discription p {
    line-height: 1.8rem;
    padding: 1.5rem 3rem;
    font-weight: 500;
    font-size: 1rem;
  }
  .home .PagesCards {
    height: 700px;
    grid-template-columns: repeat(3, 32%);
    grid-column-gap: 0.5%;
    column-gap: 0.5%;
    margin-top: 2rem;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    padding: 0rem 0rem;
    height: 550px;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 3.5rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 25px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.8rem;
    height: 400px;
    font-size: 0.84rem;
    font-family: "Montserrat";
    line-height: 1.8rem;
  }
}
@media screen and (max-width: 780px) {
  .home {
    background-size: 100vw 60vh;
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 80px;
    width: 100%;
  }
  .home .Main.heading {
    background: transparent;
    height: 62vh;
  }
  .home .Discription p {
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .home .PagesCards {
    height: 1220px;

    margin: 0 5%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    grid-column-gap: 1.5%;
    column-gap: 1.5%;
    justify-content: space-between;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    padding: 0rem 0rem;
    margin: 1rem;
    height: 380px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1.5rem;
    height: 250px;
    font-size: 11px;
    font-family: "Montserrat";
    line-height: 2.5rem;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 2.2rem;
    font-size: 20px;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 20px;
  }
  .hover-underline-animation {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 450px) {
  .home {
    background-size: 100vw 40vh;
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 50px;
    width: 100%;
  }
  .home .Main.heading {
    background: transparent;
    height: 40vh;
  }
  .home .Discription p {
    line-height: 1rem;
    padding: 0.5rem 0.5rem;
    font-weight: 500;
    font-size: 0.6rem;
  }
  .home .PagesCards {
    height: 1400px;
    margin: 0 5%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    grid-column-gap: 1.5%;
    column-gap: 1.5%;
    justify-content: space-between;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    height: 450px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1.2rem;
    height: 320px;
    font-size: 0.8rem;
    font-family: "Montserrat";
    line-height: 1.5rem;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 1.5rem;
    font-size: 14px;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 15px;
    padding-left: 2rem;
  }
  .home .PagesCards div .PageCardTitle {
    width: 100%;
    padding: 1rem 0.5rem 1rem 1rem;
    text-align: left;
    font-size: 0.8rem;
    font-family: "Montserrat";
  }
  .home .PagesCards div .PageCardTitle p {
    width: 85%;
  }
  .home .PagesCards div .PageCardTitleIcon {
    width: 37px;
    height: 37px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .hover-underline-animation {
    font-size: 0.8rem;
  }
}
/* /////////// */

/* Awards Slider  */
.Awards_Highlight {
  font-family: "Montserrat";
  font-family: Montserrat;
  text-align: center;
  color: #000000;
  background: rgba(73, 73, 73, 0.7);
  font-size: 34px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 80px;
}
.Head {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  /* background-color: #ff9b18; */
  /* z-index: 11; */
  background-color: #f0ff18;
  text-shadow: 4px 3px 3px rgb(184, 184, 184);
  padding: 0.3rem 1rem 0.2rem 1rem;
  border-radius: 0.5rem;
}
.Head {
  animation: appear 6s infinite 2s;
}
.Head:hover {
  animation-play-state: paused;
}
.Body {
  display: inline-block;
  text-align: center;
  animation: reveal 6s infinite 2s;
  padding: 0.2rem 1rem;
  line-height: 2.9rem;
}
.Body:hover {
  animation-play-state: paused;
}
.Body span {
  font-size: 24px;
  text-align: center;

  color: #ffffff;
  animation: slide 6s infinite 2s;
}
.Body span:hover {
  animation-play-state: paused;
}
.Body span .Go_To_moreAboutAwards {
  position: relative;
  top: -2px;
  font-size: 1rem;
  color: #000000;
  background-color: #37bcff;
  border: 1px solid #005783;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.Body span .Go_To_moreAboutAwards:hover {
  color: #ffffff;
  background-color: #0096e0;
  border: 1px solid #024263;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  animation-play-state: paused;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 0;
    width: 0px;
  }
  30% {
    opacity: 0.8;
    width: 680px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 680px;
  }
}
.Awards_Highlight:before {
  content: "";
  position: absolute;
  top: 150px;
  left: -300px;
  width: 150px;
  height: 160px;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation-name: slide;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-delay: 2.5s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.08) 30%,
    rgba(255, 255, 255, 0.13) 50%,
    rgba(255, 255, 255, 0.23) 70%,
    rgba(255, 255, 255, 0.3) 77%,
    rgba(255, 255, 255, 0.6) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes slide {
  0% {
    left: -100px;
    top: 0;
  }

  80% {
    left: 75%;
    top: 0;
  }

  /* 60% {
    left: 77%;
    top: 0;
  } */
  /* 70% {
    left: 80%;
    top: 0;
  } */
  /* 80% {
    left: 82%;
    top: 0;
  }
  90% {
    left: 85%;
    top: 0;
  } */

  100% {
    left: 100%;
    top: 0;
  }
}
/* Awards Slider  */
/* Awards section  */

.More_About_Award {
  margin: 2rem 4rem;
  display: flex;
  justify-content: space-between;
}
.More_About_Award .Award_Image img {
  height: 50vh;
  width: 35vw;
}
.More_About_Award .Award_Description {
  padding: 2rem;
  width: 50vw;
}
.More_About_Award .Award_Description .Award_Description_Head {
  font-size: 1.6rem;
  font-family: "Montserrat";
}
.More_About_Award .Award_Description .Award_Description_Body {
  font-size: 1.2rem;
  font-family: "Montserrat";
  margin-top: 2rem;
}
/* Awards section  */
.home.otherProduct {
  background-image: none;
}
.home.otherProduct .LinkToDelNova {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home.otherProduct .LinkToDelNova img {
  height: 120px;
  width: 160px;
  margin: 15px 50px;
  padding: 10px;
}
.home.otherProduct .LinkToDelNova img:hover {
  cursor: pointer;
  background-color: #ececec;
  border-radius: 15px;
}
.home.otherProduct .LinkToDelNova .RedirectMSG {
  font-family: "Montserrat", sans-serif;
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  text-shadow: 2px wheat;
  height: 100%;
  line-height: 4.8rem;
  font-size: 2.2rem;
  font-weight: 900;
}
@media screen and (max-width: 1100px) {
  .home.otherProduct .LinkToDelNova .RedirectMSG  {
  line-height: 3rem;
  margin: 0.5rem;
  font-size: 1.8rem;
  font-weight: 900;
  }
}
@media screen and (max-width: 780px) {
  .home.otherProduct .LinkToDelNova .RedirectMSG  {
  line-height: 2.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  }
}
@media screen and (max-width: 450px) {
  .home.otherProduct .LinkToDelNova .RedirectMSG  {
  line-height: 1.8rem;
  font-size: 1.4rem;
  }
}

/* Other Product CSS*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer {
  background-color: #cfcfcf;
  position: relative;
  display: flex;
  color: rgb(53, 34, 0);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.01rem;
}
.footer .SocialMediaLinks {
  margin: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-evenly;
}
.footer .SocialMediaLinks a img {
  margin: 0 1.2rem;
  height: 30px;
  width: 30px;
}
.footer .SocialMediaLinks {
  display: flex;
}
.footer .DunsTradeMark {
  position: absolute;
  left: 1rem;
}
.footer .DunsTradeMark a:-webkit-any-link {
  color: #3d2201 !important;
  text-decoration: none !important;
  cursor: pointer;
}

.footer .Centered {
  display: flex;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 1.4rem;

  justify-content: center;
}
.footer p {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;

  justify-content: center;
}
.Centered p {
  margin: 0;
  line-height: 1rem;
}
.footer .DunsLogo iframe {
  position: absolute;
  /* height: 50px; */
  /* width: 50px; */
  top: 0%;
  left: 0vw;
}
@media screen and (max-width: 780px) {
  .footer {
    margin-top: 5rem;

    position: relative;
    bottom: 0;
  }
  .footer .SocialMediaLinks {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .footer .SocialMediaLinks a img {
    height: 20px;
    width: 20px;
  }
  .footer p {
    font-size: 0.8rem;
  }
  .footer .DunsLogo iframe {
    position: absolute;
    top: 0%;
    left: 0vw;
  }
}
@media screen and (max-width: 450px) {
  .footer .SocialMediaLinks a img {
    height: 15px;
    width: 15px;
  }
  .footer p {
    font-size: 0.6rem;
  }
  .footer .DunsLogo iframe {
    position: absolute;
    top: 0%;
    left: 0vw;
  }
}

.companyOverview {
  /* border-bottom: 2px solid #754c17; */
  width: 100%;
}
.companyOverview .HHeading {
  /* font-size: 2.4rem; */
  padding-left: 0;
}
.companyOverviewPage .WhoWeAre {
  background-color: rgb(147, 188, 241);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 70%;
  color: #313131;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.companyOverviewPage .WhoWeAre h4 {
  width: 80%;
  color: #313131;
  font-size: 1.8rem;
  margin-left: 4rem;
  margin-right: 2rem;
  /* border-bottom: 2px solid rgb(134, 88, 18); */
  padding-bottom: 2rem;
}
.companyOverviewPage .WhoWeAre p {
  text-align: justify;
  width: 90%;
  line-height: 2.5rem;
  color: #313131;
  font-size: 1.2rem;
  margin-left: 4rem;
  margin-right: 4rem;
  padding-bottom: 2rem;
}
.companyOverviewPage .WhoWeAre p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #41403f;
  color: #081e47;
}
@media screen and (max-width: 1100px) {
  .companyOverviewPage .WhoWeAre {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    width: 100%;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-right: 1rem;
  }
  .companyOverviewPage .WhoWeAre p {
    text-align: justify;
    width: 100%;
    line-height: 2.2rem;
    font-size: 16px;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 780px) {
  .companyOverviewPage .WhoWeAre {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    font-size: 1.4rem;
  }
  .companyOverviewPage .WhoWeAre p {
    line-height: 1.5rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 450px) {
  .companyOverviewPage .WhoWeAre {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    width: 100%;
    font-size: 1.2rem;
  }
  .companyOverviewPage .WhoWeAre p {
    line-height: 1.2rem;
    font-size: 0.8rem;
  }
}

.leadershipTeam .leadershipTeamPage {
  margin: 1rem 0rem;
  /* padding: 2rem; */
  background-color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
.leadershipTeam .leadershipTeamPage .HHeading {
  text-align: left;
  font-size: 1.8rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.leadershipTeam .leadershipTeamPage .TeamMain {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  width: max-content;
  /* margin: 0 5%; */
}
.leadershipTeam .leadershipTeamPage .SecondaryTeam {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
}
.leadershipTeam .leadershipTeamPage .ThirdTeam {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
}
.leadershipTeam .leadershipTeamPage .SecondaryTeam .TeamSecond {
  display: flex;
  justify-content: space-around;
  /* width: 50%; */

  margin: 0 3rem;
}
.leadershipTeam .leadershipTeamPage .ThirdTeam .TeamThird {
  display: flex;
  justify-content: space-around;
  /* width: 50%; */

  margin: 0 3rem;
}
.leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
  background: white;
  padding: 1rem 1rem;
  width: 400px;
  height: 280px;
  /* border: 2px solid rgb(0, 106, 155); */
  /* box-shadow: rgb(66, 66, 66) 2px; */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  position: relative;
  margin: 0 1rem;
}
.TeamMainPersonDetails:hover .FrontSideCard {
  /* transition: transform 0.2s ease; */
  transform: scale(0.25);
  background-color: rgb(209, 209, 209);
  position: relative;
  top: -37%;
  left: -37%;
  border-radius: 50%;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .FrontSideCard {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  background: rgb(253, 253, 253);
  height: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: 1rem;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard {
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard
  .TeamMainPersonNAME {
  color: rgb(0, 106, 155);
  margin-left: 25%;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard
  .SpecialWords {
  text-align: justify;
  overflow-y: auto;
  height: 200px;
  font-size: 0.8rem;
  margin: 1rem;
  margin-top: 1.8rem;
}
.leadershipTeam
  .leadershipTeamPage
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails {
  text-align: center;
  margin: 0 1rem;
}
.leadershipTeam .ThirdTeam .TeamThird .TeamThirdPersonDetails {
  width: 250px;
  text-align: center;
  margin: 0 1rem;
}
.leadershipTeam .ThirdTeam .TeamThird .TeamThirdPersonDetails .TeamFourth {
  width: 120px;
  align-items: center;
  margin-top: 1rem;
  font-size: 12px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamFourth
  .TeamFourthPerson {
  font-size: 12px;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonIMG {
  border-radius: 50%;

  width: 220px;
  height: 200px;
}
.leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonIMG {
  width: 160px;
  height: 140px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonIMG {
  width: 80px;
  height: 80px;
}
/* .leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonIMG {
  width: 160px;
  height: 140px;
} */
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonNAME {
  font-weight: 600;
  font-size: 1.2rem;
}
.leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonNAME {
  font-weight: 600;
  font-size: 16px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonNAME {
  font-weight: 600;
  width: 150px;
  font-size: 14px;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonOISITION {
  font-size: 17px;
  color: rgb(75, 74, 74);
}
.leadershipTeam
  .leadershipTeamPage
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonOISITION {
  font-size: 16px;
  color: rgb(75, 74, 74);
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonOISITION {
  height: 35px;
  font-size: 12px;
  color: rgb(75, 74, 74);
}

@media screen and (max-width: 1100px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    flex-wrap: wrap;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.4rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.5rem 0.5rem;
    width: 250px;
    height: 230px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    margin: 0 0.5rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 140px;
    font-size: 0.7rem;
    margin: 0.3rem;
    margin-top: 1.5rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 14px;
    text-align: center;
    color: rgb(75, 74, 74);
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    text-align: center;

    font-size: 1rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 160px;
    height: 160px;
  }
}
@media screen and (max-width: 780px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.6rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.2rem 0.2rem;
    width: 160px;
    height: 160px;
    border-radius: 0.2rem;
    margin: 0 0.2rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
    font-size: 10px;
    margin-top: -0.2rem;

  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 100px;
    font-size: 0.5rem;
    margin: 0.1rem;
    margin-top: 0.95rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 8px;
    color: rgb(75, 74, 74);
    text-align: center;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    font-size: 8px;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 110px;
    height: 110px;
  }
}
@media screen and (max-width: 450px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.2rem 0.2rem;
    width: 160px;
    height: 160px;
    border-radius: 0.2rem;
    margin: 0.5rem 0.2rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
    font-size: 10px;
    margin-top: -0.2rem;

  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 100px;
    font-size: 0.5rem;
    margin: 0.1rem;
    margin-top: 0.95rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 8px;
    color: rgb(75, 74, 74);
    text-align: center;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    font-size: 8px;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 110px;
    height: 110px;
  }
}

.mission {
  width: 100%;
}
.MissionPage {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.MissionPage .MissionVision {
  display: grid;
  grid-template-columns: 1fr 220px 1fr;
  width: 100%;
  height: 280px;
}
.MissionPage .MissionVisionImg {
  height: 190px;
}
.MissionPage .MissionVisionImg img {
  position: relative;
  right: 50px;
  margin-top: 4rem;
  width: 250px;
}
.Special_wordIn {
  color: red;
  text-decoration: underline;
}
.mission .MissionPage .VisionSec {
  margin-top: 3rem;
  position: relative;
  margin-left: 2rem;
}
.mission .MissionPage .MissionSec {
  margin-top: 3rem;
  margin-left: 5rem;
}
.mission .MissionPage .MissionSec .heading .newFontTagline {
  color: #1490b6;
  font-size: 1.2rem;
}
.mission .MissionPage .VisionSec .heading .VisionFirstLine {
  color: #e48100;
  font-size: 0.95rem;
}
.mission .MissionPage .MissionSec .heading,
.mission .MissionPage .VisionSec .heading {
  padding-top: 0.4rem;
  font-size: 0.9rem;
  line-height: 2.5rem;
  width: 100%;
}
.mission .MissionPage .MissionSec .HHeading,
.mission .MissionPage .VisionSec .HHeading {
  margin-top: 2.5rem;
  font-size: 1.6rem;
  color: rgb(51, 51, 51);
}
/* .Mission_WhyWeAre p {
  margin: 1rem;

  font-family: "Open Sans", sans-serif;
  line-height: 2.5rem;

  font-size: 16px;
} */
.CoreValuesSec {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.CoreValuesSec .CoreValuesBottomTagLine {
  text-align: center;
  margin: 2rem;
  font-size: 1.5rem;
}
.CoreValuesSec .HHeading {
  color: #313131;
  font-size: 1.8rem;
  padding-left: 5rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
}
.CoreValuesSec .CoreValuesTagLine {
  text-align: center;
  color: #00529e;
  font-size: 1.8rem;
  padding-left: 5rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
}

/* .CoreValuesSec .CoreValues .HHeading {
  position: relative;
  top: -2%;
  height: unset;
} */
/* .CoreValuesSec .CoreValues .heading {
  height: unset;
  line-height: 1.8;
  width: 100%;
} */
.CoreValuesSec .CoreValuesImg {
  display: flex;
  justify-content: space-evenly;
}
.CoreValuesSec .CoreValuesImg .Ourvalue {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}
.CoreValuesSec .CoreValuesImg .Ourvalue img {
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
}
.CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
  text-align: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 1100px) {
  .MissionPage .MissionVision {
    display: grid;
    grid-template-columns: 1fr 180px 1fr;
    width: 100%;
  }
  .MissionPage .MissionVisionImg {
    height: 180px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    width: 210px;
  }
  .mission .MissionPage .VisionSec {
    align-items: center;
    margin-left: 2rem;
  }
  .mission .MissionPage .MissionSec {
    align-items: center;
    margin-left: 4rem;
    /* margin-left: 4vw; */
  }
  .mission .MissionPage .MissionSec .HHeading,
  .mission .MissionPage .VisionSec .HHeading {
    font-size: 1.2rem;
  }
  .mission .MissionPage .MissionSec .heading .newFontTagline {
    font-size: 0.9rem;
  }
  .mission .MissionPage .VisionSec .heading .VisionFirstLine {
    font-size: 0.7rem;
  }
  .mission .MissionPage .MissionSec .heading,
  .mission .MissionPage .VisionSec .heading {
    font-size: 0.67rem;
    line-height: 1.5rem;
    width: 100%;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .HHeading {
    font-size: 1.2rem;
    padding-left: 2rem;
    margin-right: 0rem;
    padding-bottom: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 1rem;
    padding-left: 2rem;
    padding-bottom: 1.5rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 1rem;
  }

  .CoreValuesSec .CoreValues .heading {
    height: unset;
    line-height: 1.8;
    width: 100%;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 2rem;
    width: 50px;
    height: 50px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    text-align: center;
    font-size: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .MissionPage .MissionVision {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
  }
  .MissionPage .MissionVisionImg {
    height: 100px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    top: -70px;
    width: 180px;
  }
  .mission .MissionPage .VisionSec {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  .mission .MissionPage .MissionSec {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 0.8rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 0.8rem;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    width: -moz-fit-content;
    width: fit-content;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 2rem;
    width: 40px;
    height: 40px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    text-align: center;
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 450px) {
  .MissionPage .MissionVision {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
  }
  .MissionPage .MissionVisionImg {
    height: 150px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    width: 210px;
  }
  .mission .MissionPage .VisionSec {
    margin-top: 0;
    margin-left: 0rem;
  }
  .mission .MissionPage .MissionSec {
    margin-left: 0;
  }
  .mission .MissionPage .MissionSec .HHeading,
  .mission .MissionPage .VisionSec .HHeading {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
  .mission .MissionPage .MissionSec .heading .newFontTagline {
    font-size: 0.9rem;
  }
  .mission .MissionPage .VisionSec .heading .VisionFirstLine {
    font-size: 0.7rem;
  }
  .mission .MissionPage .MissionSec .heading,
  .mission .MissionPage .VisionSec .heading {
    font-size: 0.67rem;
    line-height: 1.5rem;
    width: 100%;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .CoreValuesImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .CoreValuesSec .HHeading {
    font-size: 1.2rem;
    padding-left: 2rem;
    margin-right: 0rem;
    padding-bottom: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 1rem;
    padding-left: 2rem;
    padding-bottom: 1.5rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 1rem;
  }

  .CoreValuesSec .CoreValues .heading {
    height: -moz-fit-content;
    height: fit-content;
    line-height: 1.8;
    width: 100%;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 0rem;
    width: 50px;
    height: 50px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 0.9rem;
  }
}

.aboutUs {
  color: rgb(12, 10, 10);
  display: flex;
  width: 100%;
  flex-direction: column;
}
.aboutUs .CompanyOverview {
  width: 100%;
  margin-top: 0;
}
.aboutUs .WeBeliveIn {
  font-family: "Montserrat", sans-serif;
  position: relative;
  top: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  color: #313131;
  font-size: 1.4rem;
}
.aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
  padding-left: 8rem;
  margin-top: 2rem;
  /* padding-bottom: 3rem; */
  font-size: 1.8rem;
}

.aboutUs .WeBeliveIn .iconList {
  display: grid;
  /* grid-template-columns: repeat(2, 48%); */
  grid-row-gap: 10px;
  color: #0f7fff;
  font-size: 1.8rem;
}
.aboutUs .WeBeliveIn .iconList li img {
  height: 150px;
  width: 150px;
}
.aboutUs .WeBeliveIn .iconList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  list-style: none;
  color: #383838;
  margin-left: 0.6rem;
  margin: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.aboutUs .WeBeliveIn .iconList li span {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.aboutUs .WeBeliveIn .iconList li span .Special_wordIn {
  color: red;
  text-decoration: underline;
}
.aboutUs .WeBeliveIn h4 {
  position: relative;
  color: #083981;
  top: -1%;
  font-size: 2.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;
}

@media screen and (max-width: 1100px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1.2rem;
  }
  .aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
    padding-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .aboutUs .WeBeliveIn .iconList {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li span {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
    padding-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .aboutUs .WeBeliveIn .iconList {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li img {
    height: 90px;
    width: 90px;
  }
  .aboutUs .WeBeliveIn .iconList li {
    margin: 0rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1.2rem;
  }
  .aboutUs .WeBeliveIn .iconList li img {
    height: 70px;
    width: 70px;
  }
  .aboutUs .WeBeliveIn .iconList li {
    margin-left: 0rem;
    margin: 0.5rem;
    font-size: 0.8rem;
  }
  .aboutUs .WeBeliveIn .iconList li span {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.ApplyHere {
  margin-top: 80px;
}
.ApplyHere .HHeading {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  margin-top: 5rem;
}
.ApplyHere .heading {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: gray;
  margin: 1rem;
  font-size: 1.2rem;
}
.ApplyHere .ApplyHereForm {
  margin: 1rem auto;
  background-color: rgb(240, 240, 240);
  border-radius: 1rem;
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ApplyHere .ApplyHereForm label {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  margin: 0.8rem 2rem 0.2rem;
  width: 300px;
  /* position: relative; */
  /* left: -75px; */
}
.ApplyHere .ApplyHereForm a {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  margin: 0rem 2rem 0.2rem;
  width: 300px;
  text-decoration: none;
  color: rgb(0, 110, 161);
}
.ApplyHere .ApplyHereForm a:hover {
  color: rgb(15, 39, 179);
}
.ApplyHere .ApplyHereForm input {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  border-radius: 0.2rem;
  border: 1px solid gray;
  /* margin: 0.5rem 1rem; */
  padding: 0.5rem;
  width: 300px;
  height: 2.2rem;
  position: relative;
  /* left: -20%; */
}
.ApplyHere .ApplyHereForm input[type="file"] {
  padding: 0.15rem;
}
.ApplyHere .ApplyHereForm input[type="submit"] {
  text-align: center;
  font-size: 1.2rem;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgb(42, 147, 218);
  color: #fff;
  padding: 0.5rem 1.5rem;
}
.ApplyHere .ApplyHereForm input[type="submit"]:hover {
  background-color: rgb(1, 121, 201);
  color: #fff;
  transform: scale(0.98);
}

.ShareYourIdea {
  position: relative;
  top: 5rem;
  height: 100vh;
}

.researchAndDevelop .ResearchAndDevelopPage {
  display: flex;
  flex-direction: column;
  /* margin: 1rem 1.5rem; */
  /* margin-top: 0; */
  background-color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
.researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
  /* background-image: url("../../Image/Image\ 2.jpeg"); */
  /* background-size: 100vw 100vh; */
  /* background-position: center bottom; */
  /* background-repeat: no-repeat; */
  /* width: 100%; */
  /* margin: 0 5%; */
  height: 12vh;
}
.researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
  /* position: relative; */
  /* top: 30px; */
  width: 100%;
  font-size: 1.6rem;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  /* animation: 1s ease-out 0s 1 slideInFromRight; */
  color: rgb(1, 42, 95) !important;
  line-height: 2.2rem;
  background: transparent;
  padding: 2.5rem;
}
.researchAndDevelop .ResearchAndDevelopPage .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.researchAndDevelop .ResearchAndDevelopPage .Discription p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #081e47;
}

.ResearchAndDevelopPage .PagesCards {
  height: 570px;
  margin: 1rem 1.5rem;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 24%);
  grid-column-gap: 1%;
  column-gap: 1%;
  justify-content: space-between;
}
.ResearchAndDevelopPage .PagesCards .PageCard1,
.ResearchAndDevelopPage .PagesCards .PageCard2,
.ResearchAndDevelopPage .PagesCards .PageCard3,
.ResearchAndDevelopPage .PagesCards .PageCard4 {
  position: relative;
  padding: 0rem 0rem;
  height: 550px;
}

.ResearchAndDevelopPage .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.ResearchAndDevelopPage .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.ResearchAndDevelopPage .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid #0a3f80;
}
.ResearchAndDevelopPage .PagesCards .PageCard3:hover {
  background-color: rgba(10, 91, 189, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard4 {
  background-color: rgba(96, 49, 170, 0.2);
  border-bottom: 10px solid #6031aa;
}
.ResearchAndDevelopPage .PagesCards .PageCard4:hover {
  background-color: rgba(96, 49, 170, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard4 .PageCardTitle {
  background-color: rgba(96, 49, 170, 0.6);
}
.ResearchAndDevelopPage .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem;
  text-align: left;
  font-size: 1rem;
  font-family: "Montserrat";
}
.ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
  padding: 0.5rem 1rem;
  height: 400px;
  /* overflow-y: scroll; */
  max-height: -moz-fit-content;
  max-height: fit-content;
  font-size: 0.8rem;
  font-family: "Montserrat";
  line-height: 2rem;
}
/* .ResearchAndDevelopPage .PagesCards div a {
 
  text-decoration: none;
  color: #0f7fff;
} */
/* .ResearchAndDevelopPage .PagesCards div a:hover {
  text-decoration: none;
} */
.ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 1rem 0rem 1rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@media screen and (max-width: 1100px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 100vh; */
    height: 20vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 2rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 730px;
    margin: 1rem 1.5rem;
    grid-template-columns: repeat(4, 24.5%);
    grid-column-gap: 0.5%;
    column-gap: 0.5%;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.1rem;
    height: 700px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitle {
    padding: 1rem;
    font-size: 1rem;
    font-family: "Montserrat";
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 570px;
    font-size: 0.9rem;
    line-height: 1.8rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 80vh; */
    height: 15vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 0.5rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 1.8rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 1400px;
    margin: 1rem 1.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.5rem;
    height: 350px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 220px;
    font-size: 0.9rem;
    line-height: 2rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 60vh; */
    height: 15vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 1.5rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 1.4rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.6rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 1700px;
    margin: 1rem 1.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.5rem;
    height: 400px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 265px;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 0.8rem;
  }
}

.Contact_Us_Form {
  position: relative;
  top: 80px;
  left: 20px;
  right: 10px;
  background-color: #fff;
  border-radius: 1rem;
  width: 35%;
  padding: 0rem 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}
.Contact_Us_Form .Form {
  padding: 0.1rem 1.5rem 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Contact_Us_Form .HHeading {
  font-family: "Montserrat", sans-serif;
  color: rgb(69, 69, 70);
  font-size: 1.8rem;
  margin: 10px;
  margin-top: 20px;
  margin-left: 1.6rem;
}
.Contact_Us_Form .Contact_Us_Form_Inputs {
  width: 100%;
}
.Contact_Us_Form .Contact_Us_Form_Inputs label {
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
.Contact_Us_Form .Contact_Us_Form_Inputs input,
.Contact_Us_Form .Contact_Us_Form_Inputs .Message_contactForm {
  width: 100%;
  height: 2rem;
  margin-left: 3px;
  padding-left: 8px;
  font-size: 1.2rem;
  color: rgb(43, 43, 43);
  border-radius: 5px;
  border: 0.8px solid gray;
  border-bottom: 3px solid gray;
  border-right: 2px solid gray;
  margin-bottom: 7px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Contact_Us_Form .Contact_Us_Form_Inputs textarea {
  height: 6rem !important;
  padding-top: 3px;
}
.Message_contactForm {
  height: 5rem;
  width: 90%;
}
.Contact_Us_Form .Form input[type="submit"] {
  text-align: center;
  background-color: #0270ca;
  color: rgb(228, 230, 231);
  padding: 5px;
  margin-top: 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  width: 30%;
}
.Contact_Us_Form .Form input[type="submit"]:hover {
  text-align: center;
  color: rgb(255, 255, 255);
  width: 30%;
  transform: scale(0.97);
}
@media screen and (max-width: 1100px) {
  .Contact_Us_Form {
    position: relative;
    top: 80px;
    left: 0;

    right: 10px;
    background-color: #fff;
    border-radius: 1rem;
    width: 40%;
    padding: 0rem 0.5rem;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 0.5rem;
    font-size: 1rem;
    width: 50%;
  }
}
@media screen and (max-width: 780px) {
  .Contact_Us_Form {
    position: relative;
    top: 0;
    right: 0;
    width: 70%;
    padding: 0rem;
    margin: 0rem 15%;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 1rem;
    font-size: 1.2rem;
    width: 40%;
  }
}
@media screen and (max-width: 450px) {
  .Contact_Us_Form {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    padding: 0rem;
    margin: 0rem 5%;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    width: 100%;
  }
}

.ourPresence {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.ourPresence .Our_Presence_Bg {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%;
  position: relative;
  background-image: url(/static/media/OurPresence2.c9826350.png);
  background-size: 100% 400px;
  background-position: right;
  background-repeat: no-repeat;
  display: none;
}
.ourPresence .Our_Presence_Bg .HHeading {
  position: relative;
  width: 90%;
  top: 42%;
  color: rgb(27, 49, 97);
}
.ourPresence .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.ourPresence .Discription p span {
  font-weight: 750;
  font-style: italic;
  color: #41403f;
  color: #081e47;
}
.ourPresence h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: rgb(0, 29, 71);
}
.ourPresence .OurPresenceMap {
  height: 70vh;
  width: 90%;
  background-image: url("/static/media/Our Presence Map.b1e5d091.png");
  background-size: 90% 70vh;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
.carousel .carousel-status {
  display: none;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
  height: 100px;
  width: 150px !important;
}
.OurPresenceCarousel .carousel-root {
  height: 70vh;
  width: 65vw;
  margin-bottom: 5rem;
}
.OurPresenceCarousel .carousel-root div img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .ourPresence .Our_Presence_Bg {
    height: 30vw;
    background-size: 100% 30vw;
  }
  .ourPresence .Discription p {
    line-height: 1.8rem;
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
  .ourPresence h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 780px) {
  .ourPresence .Our_Presence_Bg .HHeading {
    font-size: 2rem;
  }
  .ourPresence .Discription p {
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  .ourPresence h1 {
    font-size: 1.8rem;
  }
  .ourPresence .OurPresenceMap {
    height: 50vh;
    width: 90%;
    background-size: 90% 50vh;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 450px) {
  .ourPresence .Our_Presence_Bg .HHeading {
    font-size: 1.2rem;
  }
  .ourPresence .Discription p {
    line-height: 1.2rem;
    padding: 0.5rem 1rem;
    font-size: 0.6rem;
  }
  .ourPresence h1 {
    font-size: 1.2rem;
  }
  .ourPresence .OurPresenceMap {
    height: 26vh;
    width: 90%;
    background-size: 90% 26vh;
    margin-bottom: 0rem;
  }
}

.pipeline {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.pipeline .PipelineBG {
  display: flex;
  justify-content: center;
  height: 60vh;
  width: 100%;
  position: relative;
  top: 80px;
  background-image: url(/static/media/Formulation-Development-banner.0cb89946.jpeg);
  /* background-image: url("../../Image/Neutraceuticals.jpeg"); */
  background-size: 100% 60vh;
  background-position: center;
  background-repeat: no-repeat;
}
.pipeline .PipelinePage {
  width: 100%;
}
.pipeline .Discription {
  width: 100%;
}
.pipeline .Discription p {
  font-family: "Montserrat", sans-serif;
  position: relative;
  top: 80px;
  text-align: center;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
  /* border-bottom: 2px dotted #df6c00; */
}
.pipeline .Border_Bottom {
  margin-top: 80px;
  /* position: relative; */
  /* top: 80px; */
}
.pipeline .CDMO {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pipeline .CDMO img {
  /* color: red; */
  height: 500px;
  width: 90%;
}
.pipeline .CDMO .HHeading {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.2rem;
}
.pipeline .PagesCards {
  position: relative;
  /* height: 530px; */
  margin: 1rem 1.5rem;
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(4, 24%);
  grid-column-gap: 1%;
  column-gap: 1%;
  justify-content: space-between;
}
.pipeline .PagesCards .HHeading {
  position: absolute;
  top: -3.5rem;
  font-size: 1.4rem;
  width: 100%;
}
.pipeline .PagesCards .PageCard1,
.pipeline .PagesCards .PageCard2,
.pipeline .PagesCards .PageCard3,
.pipeline .PagesCards .PageCard4 {
  position: relative;
  padding: 0rem 0rem;
  height: 550px;
}

.pipeline .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.pipeline .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.4);
}
.pipeline .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.pipeline .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.4);
}
.pipeline .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid #0a3f80;
}
.pipeline .PagesCards .PageCard3:hover {
  background-color: rgba(10, 91, 189, 0.4);
}
.pipeline .PagesCards .PageCard4 {
  background-color: rgba(96, 49, 170, 0.2);
  border-bottom: 10px solid #6031aa;
}
.pipeline .PagesCards .PageCard4:hover {
  background-color: rgba(96, 49, 170, 0.4);
}
.pipeline .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.pipeline .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.pipeline .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
}
.pipeline .PagesCards .PageCard4 .PageCardTitle {
  background-color: rgba(96, 49, 170, 0.6);
}
.pipeline .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem 1.2rem 1.2rem 0.5rem;
  text-align: left;
  font-size: 1rem;
  font-family: "Montserrat";
}
.pipeline .PagesCards div .PageCardTitleIcon {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 5px;
  top: 15px;
}

.pipeline .PagesCards div .PageDiscriptionShort {
  padding: 0.5rem 1rem;
  height: 380px;
  /* overflow: hidden; */

  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 2.5rem;
}
.pipeline .PagesCards div .PageLink .hover-underline-animation {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 1rem 0rem 1rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.pipeline .DifferentiatedProduct .PipelinePage .DifferentiatedProductTopBG,
.pipeline .InnovativeProduct .PipelinePage .InnovativeProductTopBG,
.pipeline .ComplexGenerics .PipelinePage .ComplexGenericsTopBG,
.pipeline .GenericsProduct .PipelinePage .GenericsProductTopBG {
  display: none;
}
.pipeline .DifferentiatedProduct .PipelinePage .product_List,
.pipeline .InnovativeProduct .PipelinePage .product_List,
.pipeline .ComplexGenerics .PipelinePage .product_List,
.pipeline .GenericsProduct .PipelinePage .product_List {
  font-family: "Montserrat", sans-serif;
}
.pipeline .InnovativeProduct.FullSize,
.pipeline .ComplexGenerics.FullSize,
.pipeline .GenericsProduct.FullSize {
  margin-top: 0 !important;
}
.PipelinePage .ComplexGenericsTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  /* background-image: url("../../Image/ComplexGenericProduct.png"); */
  background-size: 90% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .DifferentiatedProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url(/static/media/pre-formulation-development-api-2048x878.d85cffaf.jpeg);
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .InnovativeProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url("/static/media/Innovative Product.157515d5.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
/* .PipelinePage .NutraceuiticalProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;

  background-image: url("../../Image/Innovative\ Neutraceuticals.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
} */
.PipelinePage .GenericsProductTopBG {
  justify-content: center;
  height: 20vw;
  width: 90%;
  margin: 0 5%;
  position: relative;
  background-image: url("/static/media/Generic Product.aee53264.jpeg");
  background-size: 100% 26vw;
  background-position: center;
  background-repeat: no-repeat;
}
.PipelinePage .PreFormulServicesTopBG,
.PipelinePage .FormulDevelopServicesTopBG,
.PipelinePage .TechnicalServicesTopBG,
.PipelinePage .AnalyticalServicesTopBG {
  justify-content: center;
  height: 50vh;
  width: 90%;
  margin: 0 5%;
  position: relative;
  /* background-image: url("../../Image/Oral\ DD1.jpg"); */
  background-size: 100% 60vh;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}
.PipelinePage .Pipeline .HHeading {
  font-family: "Montserrat", sans-serif;
  /* text-align: center; */
  padding-left: 5rem;
  color: rgb(1, 27, 75);
  font-size: 1.6rem;
  margin-bottom: 0rem;
  margin-top: 2rem;
}
/* ?????? */
/* ?????? */
/* ?????? */
.product_List {
  width: 100%;
}
.product_List .HHeading {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  margin: 1rem;
  text-align: left;
  margin-left: 5rem;
}
.product_List .ShortDiscription {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 2.2rem;
  margin: 0.8rem 5rem;
  /* text-align: center; */
}
.Services_List .ShortDiscription {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 2.2rem;
  margin: 2rem 5rem;
  /* text-align: center; */
}
.product_List .ProdDevProgressDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product_List .ProdDevProgressDetails .ProductSection {
  background-color: rgb(248, 248, 248);
  /* background-color: rgb(161, 153, 153); */
  border-radius: 1.5rem;
  padding-top: 2rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  position: relative;
  width: 980px;
}
.product_List .ProdDevProgressDetails .ProductSection .Tbody {
  /* background-color: rgb(70, 161, 48); */
  /* margin-bottom: 1.5rem; */
  padding: 0;
  position: relative;
  left: -140px;
  top: -76px;
  /* display: flex; */
}
.product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
  /* background-color: aqua; */
  position: absolute;
  left: 680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 3rem; */
  font-size: 1.2rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress {
  margin: 0 0.5rem;
}
.product_List .ProdDevProgressDetails .ProductSection .ProductName {
  font-family: "Montserrat", sans-serif;

  color: rgb(15, 60, 126);
  margin-left: 3rem;
  font-size: 1rem;
  width: 440px;
}
.product_List .ProdDevProgressDetails .ProductSection .ProductRLDdev {
  font-family: "Montserrat", sans-serif;

  position: relative;
  left: 0;
  color: rgb(13, 98, 209);
  font-size: 12px;
  font-weight: 700;
  padding: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  margin-left: 3rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60px;
}
.table-row .ProdDevProgress .fas.fa-exclamation-circle {
  align-self: center;
  display: block;
  color: rgb(233, 158, 97);
  background-color: #fff !important;
  border-radius: 50%;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-check-circle {
  align-self: center;
  display: block;
  color: rgb(50, 163, 69);
  border-radius: 50%;
  background-color: #fff !important;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-times-circle {
  align-self: center;
  display: block;
  border-radius: 50%;
  color: rgb(190, 185, 185);
  background-color: #fff;
  margin-bottom: 0.2rem;
  z-index: 5;
}
.table-row .ProdDevProgress .fas.fa-sync {
  align-self: center;
  display: block;
  color: rgb(27, 99, 167);
  background-color: rgb(255, 255, 255);
  margin-bottom: 0rem;
  padding: 0.2rem;
  border-radius: 50%;
  animation: 2s linear 0s 10 rotateAtPoint;
  font-size: 1.5rem;
  z-index: 5;
}
.table-row .ProdDevProgress p {
  font-family: "Montserrat", sans-serif;

  align-self: center;
  font-size: 0.6rem;

  width: max-content;
}
.table-row .ProdDevProgress i {
  font-size: 1.5rem;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress::after {
  content: "";
  position: relative;
  top: -32px;
  transform: translatex(-9%);
  height: 8px;
  width: 80px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress:first-child:after {
  content: "";
  background-color: rgb(192, 185, 185);
  position: relative;
  top: -32px;
  transform: translatex(50%);
  height: 8px;
  width: 48px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress:last-child:after {
  content: "";
  background-color: rgb(192, 185, 185);
  position: relative;
  top: -32px;
  transform: translatex(-22%);
  height: 8px;
  width: 35px;
  display: block;
  z-index: 1;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.GreenProLine::after {
  background-color: rgb(113, 197, 127);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.GrayProLine::after {
  background-color: rgb(190, 185, 185);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.OrangeProLine::after {
  background-color: rgb(233, 158, 97);
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .Tbody
  .table-row
  .ProdDevProgress.BlueProLine::after {
  position: relative;
  background-color: rgb(97, 149, 197);
  margin-bottom: 3px;
}

.OutLicensing_List .OutLicTable {
  font-family: "Montserrat", sans-serif;

  border: 1px solid rgb(128, 128, 128);
  border-radius: 5px;
  border-collapse: collapse;
  z-index: 2;
  margin-top: 0.5rem;
  margin-left: 3rem;
  margin-bottom: 1.5rem;
  width: max-content;
  position: relative;
}
.OutLicensing_List .OutLicTable .OutLicTableHead {
  border-collapse: collapse;
}
.OutLicensing_List .OutLicTable .OutLicTableBody {
  border-collapse: collapse;
}
.OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
  /* background-color: rgb(91, 142, 209); */
  color: rgb(14, 65, 107);
  width: 80px;
  font-size: 14px;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
  border: 1px solid rgb(117, 116, 116);
  border-collapse: collapse;
  font-weight: 700;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue {
  color: rgb(14, 65, 107);
  font-weight: 900;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicRemarkRow {
  color: rgb(14, 65, 107);
  font-weight: 900;
  font-size: 14px;
  width: 100%;
}
.OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicRemarkRow
  .outLicRemarValue {
  border-right: 1px solid rgb(116, 116, 116);
  padding: 0.2rem 0.5rem 0.2rem;
  color: rgb(14, 65, 107);
  font-weight: 900;
  font-size: 14px;
}
/* .OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicRemarkRow
  .outLicRemarValue
  .ProductTableBTN {
  text-decoration: none;
  border-radius: 0.5rem;
  margin-left: 1.2rem;
  background-color: rgb(17, 111, 199);
  padding: 0.12rem 0.6rem 0.15rem;
  color: rgb(250, 250, 250);
  font-size: 14px;
} */
.OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(124, 124, 124);
  color: black;
  color: rgb(14, 65, 107);
  font-weight: 700;
  border-collapse: collapse;
  padding: 0.3rem 0.5rem;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
  padding: 0.2;
  text-align: center;
}
.OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue.Avaliable {
  color: rgb(35, 182, 60);
}

.OutLicensing_List
  .OutLicTable
  .OutLicTableBody
  .outLicTableValue.notAvaliable {
  color: rgb(243, 54, 54);
}
.product_List .ProdDevProgressDetails .ProductSection .OutLicensing_List {
  display: none;
}
.product_List
  .ProdDevProgressDetails
  .ProductSection
  .OutLicensing_List.viewMore {
  display: block;
}
.product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(54, 117, 233);
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-left: 3rem;
  margin-bottom: 0.2rem;
  position: relative;
  /* left: 645px; */
  /* top: 27px; */

  display: block;
}
.product_List .ProdDevProgressDetails .ProductSection .onclickoutlic:hover {
  background-color: rgb(29, 82, 180);
  color: #fff;
  transform: scale(0.98);
  display: block;
}

.OutLicTable .OutLicTableBody .outLicContactUsBTNRow {
  width: 100%;
  margin: 0 auto;
}
.OutLicTable .OutLicTableBody .outLicContactUsBTNRow .outLicContactUsBTN {
  align-self: center;
  padding: 0.3rem;
  font-size: 0;

  border-top: 1px solid rgb(124, 124, 124);
}
.OutLicTable
  .OutLicTableBody
  .outLicContactUsBTNRow
  .outLicContactUsBTN
  .ProductTableBTN {
  margin-left: 38%;
  text-decoration: none;
  color: rgb(250, 250, 250);
  padding: 0.12rem 0.6rem 0.1rem;
  font-size: 14px;
  border-radius: 0.5rem;
  background-color: rgb(17, 111, 199);
}

@media screen and (max-width: 1100px) {
  .pipeline .PipelineBG {
    height: 30vw;
    top: 80px;
    background-size: 100% 30vw;
  }
  .pipeline .Discription p {
    top: 80px;
    line-height: 1.8rem;
    padding: 1rem 2rem;
    font-weight: 400;
    font-size: 1rem;
  }
  .pipeline .CDMO img {
    height: 55vw;
    width: 98%;
  }
  .pipeline .CDMO .HHeading {
    font-size: 1rem;
  }
  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    grid-template-columns: repeat(2, 48%);
    grid-column-gap: 2%;
    column-gap: 2%;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 400px;
    margin-bottom: 1.5rem;
  }
  .pipeline .PagesCards div .PageCardTitle {
    padding: 1.2rem;
    text-align: left;
    font-size: 1rem;
    font-family: "Montserrat";
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1rem;
    height: 260px;
    font-size: 1rem;
    line-height: 2.2rem;
  }
  /* productcard */
  .PipelinePage .PreFormulServicesTopBG,
  .PipelinePage .FormulDevelopServicesTopBG,
  .PipelinePage .TechnicalServicesTopBG,
  .PipelinePage .AnalyticalServicesTopBG {
    height: 30vw;
    width: 98%;
    margin: 0 1%;
    background-size: 100% 30vw;
  }
  .PipelinePage .Pipeline .HHeading {
    padding-left: 4rem;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .product_List .ShortDiscription {
    font-size: 0.9rem;
    line-height: 2.2rem;
    margin: 1rem 4rem;
  }
  .product_List .HHeading {
    font-size: 1.2rem;
    margin-left: 4rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection {
    border-radius: 1.5rem;
    padding-top: 2rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    position: relative;
    width: 540px;
  }
  .product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
    position: absolute;
    left: 200px;
    top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-left: 3rem;
    margin-bottom: 2.2rem;
    position: relative;
    top: 45px;

    display: block;
  }
  .OutLicensing_List .OutLicTable {
    top: 1.2rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
    width: 60px;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.2rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
  .OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.5rem;
  }
  .OutLicensing_List
    .OutLicTable
    .OutLicTableBody
    .outLicRemarkRow
    .outLicRemarValue {
    padding: 0.2rem 0.5rem 0.2rem;
    font-weight: 600;
    font-size: 10px;
  }
}
@media screen and (max-width: 780px) {
  .outLicTableValue .fa-times {
    color: rgb(243, 54, 54);

    font-size: 12px;
  }
  .pipeline .PipelineBG {
    height: 30vw;
    top: 80px;
    background-size: 100% 30vw;
  }

  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 330px;
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    height: 180px;
  }
  /* productcard */
}
@media screen and (max-width: 550px) {
  .pipeline .PipelineBG {
    top: 50px;
  }
  .pipeline .Discription p {
    top: 50px;
    text-align: justify;
    line-height: 1rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.6rem;
  }
  .pipeline .CDMO .HHeading {
    font-size: 0.9rem;
  }
  .pipeline .PagesCards {
    margin: 1rem 1.5rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
  }
  .pipeline .PagesCards .PageCard1,
  .pipeline .PagesCards .PageCard2,
  .pipeline .PagesCards .PageCard3,
  .pipeline .PagesCards .PageCard4 {
    height: 430px;
    margin-bottom: 1.5rem;
  }
  .pipeline .PagesCards .HHeading {
    top: -4.5rem;
    font-size: 1rem;
    width: 100%;
  }
  .pipeline .PagesCards div .PageCardTitle {
    padding: 1rem;
    font-size: 0.9rem;
  }
  .pipeline .PagesCards div .PageDiscriptionShort {
    height: 275px;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  /* productcard */
  .PipelinePage .PreFormulServicesTopBG,
  .PipelinePage .FormulDevelopServicesTopBG,
  .PipelinePage .TechnicalServicesTopBG,
  .PipelinePage .AnalyticalServicesTopBG {
    height: 30vw;
    width: 98%;
    margin: 0 1%;
    background-size: 100% 30vw;
  }
  .PipelinePage .Pipeline .HHeading {
    padding-left: 1.5rem;
    font-size: 1rem;
    margin-top: 0rem;
  }
  .product_List .ShortDiscription {
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin: 1rem 1.5rem;
  }
  .product_List .HHeading {
    font-size: 1rem;
    margin-left: 1.5rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection {
    border-radius: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin: 0 auto;
    margin-bottom: 1.2rem;
    width: 98%;
  }
  .product_List .ProdDevProgressDetails .ProductSection .ProductName {
    margin-left: 1rem;
    font-size: 0.8rem;
    width: 90%;
  }
  .product_List .ProdDevProgressDetails .ProductSection .ProductRLDdev {
    position: relative;
    left: 0;
    font-size: 10px;
    margin-left: 1rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .Tbody .table-row {
    position: absolute;
    left: 160px;
    top: 25px;
    font-size: 0.8rem;
  }
  .table-row .ProdDevProgress .fas.fa-exclamation-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-check-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-times-circle {
    font-size: 1rem;
  }
  .table-row .ProdDevProgress .fas.fa-sync {
    font-size: 1rem;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress::after {
    top: -22px;
    height: 4px;
    width: 60px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress:first-child:after {
    top: -22px;
    height: 4px;
    width: 36px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress:last-child:after {
    top: -22px;
    height: 4px;
    width: 26px;
  }
  .product_List
    .ProdDevProgressDetails
    .ProductSection
    .Tbody
    .table-row
    .ProdDevProgress {
    width: 40px;
  }
  .table-row .ProdDevProgress p {
    font-size: 0.4rem;
  }
  .product_List .ProdDevProgressDetails .ProductSection .onclickoutlic {
    color: #fff;
    padding: 0.1rem 0.2rem;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 2rem;
    position: relative;
    top: 32px;
    font-size: 0.6rem;

    display: block;
  }
  .OutLicensing_List .OutLicTable {
    top: 0.25rem;
    z-index: 2;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
    width: max-content;
    position: relative;
  }
  .OutLicensing_List .OutLicTable .OutLicTableHead .OutLicTableHeadValue {
    width: 40px;
    font-size: 8px;
    font-weight: 600;
    padding: 0.1rem 0.1rem;
  }
  .OutLicensing_List .OutLicTable .OutLicTableBody .OutLicTableBodyValue,
  .OutLicensing_List .OutLicTable .OutLicTableBody .outLicTableValue {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding: 0.3rem 0.5rem;
  }
  .OutLicensing_List
    .OutLicTable
    .OutLicTableBody
    .outLicRemarkRow
    .outLicRemarValue {
    padding: 0.2rem 0.5rem 0.2rem;
    font-weight: 600;
    font-size: 10px;
  }
}

.careers .CareersPage {
  background-color: #fff;
  width: 100%;
  height: max-content;
}
.careers .CareersPage .CareersBG {
  background-image: url(/static/media/career-banner.ebdae0cb.jpg);
  animation: 1.2s ease-out 0s 1 scaleOnPoint;
  background-size: 90vw 30vw;
  background-position: top;
  background-repeat: no-repeat;
  width: 90%;
  margin: 1rem 5%;
  height: 25vw;
}
.careers .CareersPage .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.careers .CareersPage .Discription p span {
  font-weight: 750;
  font-size: 1.4rem;
  font-style: bold;
}
.careers .CareersPage .CareersBG .HHeading {
  font-size: 7rem;
  font-weight: 800;
  color: rgb(255, 255, 255);
  font-family: "open sans";
  background: transparent;
  padding: 2rem;
}
.careers .CareersPage .JobSection {
  border: 2px solid #ff950f;
  border-radius: 0.5rem;
  background-color: rgba(255, 159, 15, 0.3);
  padding: 1rem;
  margin: 2rem 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobSection ul {
  width: 60%;
  list-style: none;
  text-decoration: underline;
  -webkit-text-decoration-color: #ffb30f;
          text-decoration-color: #ffb30f;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: space-between;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobOffers {
  margin: 2rem 5vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobOffers .JobOffer_Details {
  /* width: 668px; */
  height: 180px;
  overflow-y: hidden;
  padding: 1rem;
  padding-left: 1.5rem;
  border: 1px solid rgb(119, 115, 115);
  border-left: 5px solid #0fa7ff;
  border-radius: 0.5rem;
  display: inline-block;
  position: relative;
}
.careers .CareersPage .JobOffers .JobOffer_Details.viewMore {
  /* background-color: green; */
  width: 100%;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 1rem;
  padding-left: 1.5rem;
  border: 1px solid rgb(119, 115, 115);
  border-left: 5px solid #0fa7ff;
  border-radius: 0.5rem;
  display: inline-block;
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
  font-size: 1.6rem;
}

.careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements,
.careers .CareersPage .JobOffers .JobOffer_Details .TechnicalReq {
  position: absolute;
  left: 150px;
  width: 30%;
  margin-left: 1.2rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: rgb(109, 108, 105);
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements li,
.careers .CareersPage .JobOffers .JobOffer_Details .TechnicalReq li {
  width: max-content;
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Location {
  font-size: 0.8rem;
  color: rgb(70, 66, 66);
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Location i {
  position: relative;
  top: 0.3rem;
  color: rgb(55, 171, 238);
}
.careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
  padding: 0 1rem;
  margin-top: 0.8rem;
  font-size: 1rem;
  color: rgb(70, 66, 66);
}
.sec__heading {
  margin-top: 2.2rem;
  font-size: 1.1rem;
}
.careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription p {
  margin-top: 1rem;
}
.careers
  .CareersPage
  .JobOffers
  .JobOffer_Details
  .JobDiscription
  .ViewMoreApplyHereBTN
  a {
  margin-top: 1rem;
  /* display: block; */
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid black;
  font-size: 1rem;
  text-decoration: none;
  background-color: #2272ce;
  color: rgb(255, 255, 255);
}
.careers
  .CareersPage
  .JobOffers
  .JobOffer_Details
  .JobDiscription
  .ViewMoreApplyHereBTN
  a:hover {
  background-color: #0362ce;
  transform: scale(0.98);
  cursor: pointer;
}

.careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
  font-family: "Montserrat", sans-serif;

  position: relative;
  /* bottom: 0%; */
  /* top   : 10%; */
  /* right: -500px; */
  background-color: rgb(226, 226, 226);
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
  font-size: 1rem;
  color: rgb(70, 66, 66);
}
@media screen and (max-width: 1100px) {
  .careers .CareersPage .CareersBG {
    background-size: 90vw 30vw;
    width: 100%;
    margin: 0 0;
    height: 25vw;
  }
  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 1.4rem;
  }
  .careers .CareersPage .Discription p {
    width: 100%;
    line-height: 2.5rem;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.5rem 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 780px) {
  .careers .CareersPage {
    min-height: 90vh;
    height: -moz-fit-content;
    height: fit-content;
  }

  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .careers .CareersPage .Discription p {
    line-height: 1.8rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1.2rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements {
    position: absolute;
    left: 130px;
    width: 30%;
    margin-left: 0.1rem;
    font-size: 0.9rem;
  }

  .careers .CareersPage .JobOffers .JobOffer_Details {
    height: 120px;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-left: 0.8rem;
    position: relative;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
    padding: 0 1rem;
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
    position: relative;
    top: -10px;
    padding: 0.2rem 0.4rem;
    border-radius: 0.8rem;
    font-size: 0.8rem;
    color: rgb(70, 66, 66);
  }
  /* .careers
    .CareersPage
    .JobOffers
    .JobOffer_Details
    .JobDiscription
    .ViewMoreApplyHereBTN
    a {
    margin-top: 1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.8rem;
    font-size: 0.8rem;
  } */
}
@media screen and (max-width: 450px) {
  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 0.8rem;
  }
  .careers .CareersPage .Discription p {
    text-align: justify;
    line-height: 1.2rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.6rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.2rem 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements {
    position: absolute;
    left: 110px;
    width: 30%;
    margin-left: 0.1rem;
    margin-top: 5px;
    font-size: 0.8rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details {
    height: 115px;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-left: 0.8rem;
    position: relative;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
    padding: 0 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
  /* .careers
    .CareersPage
    .JobOffers
    .JobOffer_Details
    .JobDiscription
    .ViewMoreApplyHereBTN
    a {
    margin-top: 1rem;
    padding: 0.1rem 0.2rem;
    border-radius: 0.5rem;
    font-size: 0.6rem;
  } */
  .careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
    padding: 0.2rem 0.3rem;
    border-radius: 0.8rem;
    font-size: 0.6rem;
  }
}

.ContactUs .ContactUsBG {
  position: relative;
  top: 80px;
  background-image: url(/static/media/contact3.4f4044f1.jpg);
  background-size: 100vw 60vh;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 40vh;
  margin-bottom: 2rem;
}
.ContactUsMain {
  display: flex;
  flex-direction: row;
}
.ContactUsMain .CompanyDetails {
  padding: 6rem 3rem 1.5rem;
  color: rgb(54, 52, 52);
  border-radius: 2rem;
  position: relative;
  top: 26%;
  left: 5%;
  height: 700px;
  width: 40%;
  margin-right: 10%;
}

.CompanyDetails h3 {
  font-family: "Audiowide", sans-serif;
  font-weight: 300;
  color: rgb(2, 2, 97);
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

.CompanyDetails p {
  width: 140%;
  font-family: "Montserrat", sans-serif;

  font-weight: 300;
  color: rgb(51, 51, 51);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.Special_In {
  color: red;
  text-decoration: none;
}
.CompanyDetails .ContactDetailsUL {
  display: grid;
  grid-template-columns: 30% 68%;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  grid-column-gap: 0.8rem;
  column-gap: 0.8rem;
  padding-left: 0.2rem;
}
.CompanyDetails .Contact {
  font-family: "Montserrat", sans-serif;

  list-style: none;
  line-height: 2.4rem;
  font-size: 16px;
  font-weight: 400;
}
.ContactDetailsUL a {
  text-decoration: none;
}
.ContactDetailsUL a button {
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2988be;
  color: #f0f0f0;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  font-size: 14px;
}
.ContactDetailsUL a button:hover {
  background-color: #0e679b;
  color: #ffffff;
  cursor: pointer;
  transform: scale(0.98);
}
.ContactDetailsUL a .GetDirectionsBTN {
  position: relative;
  top: 20%;
  height: 50%;
}

.Contact.WhatsApp i {
  color: rgb(22, 150, 86);
  position: relative;
  top: 0.35rem;
}

@media screen and (max-width: 1100px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 80px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain .CompanyDetails {
    padding: 6rem 1.5rem 1.5rem 2rem;
    top: 26%;
    left: 0;
    height: -moz-fit-content;
    height: fit-content;
    width: 40%;
    margin-right: 10%;
    margin-bottom: 10%;
  }

  .CompanyDetails h3 {
    font-size: 1.5rem;
  }
  .CompanyDetails p {
    width: 100%;
    font-size: 1rem;
  }

  .CompanyDetails .ContactDetailsUL {
    width: 120%;
    display: grid;
    grid-template-columns: 26% 82%;
    grid-row-gap: 0.8rem;
    row-gap: 0.8rem;
    grid-column-gap: 0.8rem;
    column-gap: 0.8rem;
    padding-left: 0.2rem;
  }
}
@media screen and (max-width: 780px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 80px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain {
    flex-direction: column;
  }
  .CompanyDetails h3 {
    font-size: 1.2rem;
  }
  .CompanyDetails p {
    font-size: 1rem;
  }

  .ContactUsMain .CompanyDetails {
    padding: 6rem 3rem 1.5rem;
    color: rgb(54, 52, 52);
    border-radius: 2rem;
    position: relative;
    top: 26%;
    left: 0;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }
  .CompanyDetails .ContactDetailsUL {
    width: 90%;
    display: grid;
    grid-template-columns: 26% 65%;
    grid-row-gap: 0.8rem;
    row-gap: 0.8rem;
    grid-column-gap: 0.8rem;
    column-gap: 0.8rem;
    padding-left: 0.2rem;
  }
  .CompanyDetails .Contact {
    line-height: 2rem;
    font-size: 14px;
    font-weight: 400;
  }
  .ContactDetailsUL a button {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 50px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain {
    flex-direction: column;
  }
  .ContactUsMain .CompanyDetails {
    padding: 3rem 2rem 1.5rem;
    border-radius: 2rem;
    top: 22%;
  }
  .CompanyDetails h3 {
    font-size: 1rem;
  }
  .CompanyDetails p {
    font-size: 0.8rem;
  }
  .CompanyDetails .Contact {
    font-size: 12px;
  }
  .ContactDetailsUL a button {
    font-size: 10px;
  }
}

.pipeline div .ContactUs_Card {
  display: none !important;
  /* display: flex; */
}
.ContactUs_Card {
  background-color: rgb(248, 232, 214);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.8);
  width: 200px;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
  position: fixed;
  bottom: 2rem;
  right: 1rem !important;
  display: flex;
  flex-direction: column;
  z-index: 15;
}
.ContactUs_Card .Card_Question {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ContactUs_Card .Card_Question .Ques {
  color: rgb(7, 99, 122);
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem;
  font-size: 0.8rem;
}
.ContactUs_Card .Card_Question .ContactBTN {
  background-color: rgb(143, 207, 236);
  font-family: "Montserrat", sans-serif;
  padding: 0.2rem 1rem;
  margin: 0.5rem 0.5rem;
  font-size: 1rem;
  border-radius: 1rem;
}
.ContactUs_Card .Card_Question .ContactBTN a {
  text-decoration: none;
  color: black;
}
.ContactUs_Card .OtherProducts div {
  display: flex;
  flex-direction: column;
}
.ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
  position: relative;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0.2rem 0.1rem;
  border-radius: 1rem;
  border: 1px solid gray;
  background-color: rgb(34, 98, 180);
  color: #fff;
}
.ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
  position: absolute;
  top: 10px;
  right: 0.5rem;
  display: inline-block;
}
@media screen and (max-width: 780px) {
  .ContactUs_Card {
    width: 120px;
    border-radius: 0.5rem;
    padding: 0.1rem 0.15rem;
    position: fixed;
    bottom: 2rem;
    right: 0.1rem !important;
  }
  .ContactUs_Card .Card_Question .Ques {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
    font-size: 0.6rem;
    text-decoration: none;
    padding: 0.2rem 0.5rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
    top: 5px;
  }
  .ContactUs_Card .Card_Question .ContactBTN {
    padding: 0.2rem 1rem;
    margin: 0.15rem 0.15rem;
    font-size: 0.6rem;
    border-radius: 1rem;
  }
  .ContactUs_Card .Card_Question .ContactBTN a {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 450px) {
  .ContactUs_Card {
    width: 90px;
  }
  .ContactUs_Card .Card_Question .Ques {
    padding: 0.5rem;
    font-size: 0.5rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
    font-size: 0.45rem;
    margin: 0.1rem 0.05rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
    top: 4px;
  }
  .ContactUs_Card .Card_Question .ContactBTN {
    padding: 0.1rem 0.1rem;
    margin: 0.05rem 0.05rem;
    font-size: 0.5rem;
    border-radius: 1rem;
  }
  .ContactUs_Card .Card_Question .ContactBTN a {
    font-size: 0.5rem;
  }
}

.Card_ContainerTechPlat {
  margin: 15px;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.TechSerCard {
  position: relative;
  font-family: "Montserrat", sans-serif;
  height: 450px;
  width: 450px;
  padding: 20px;
  margin: 5px;
  margin-top: 110px;

  background-color: rgb(241, 241, 241);
  border-radius: 8px;
}
.TechSerCard .IconCircle {
  height: 120px;
  width: 120px;
  border-radius: 60px;
  position: absolute;
  top: -60px;
  left: 165px;
  background-color: rgb(180, 180, 180);
  padding: 10px;
}
.TechSerCard .IconCircle p {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: rgb(205, 208, 250);
}
.TechSerCard .CardTitle {
  position: relative;
  text-align: center;
  top: -120px;
  left: -10px;
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  text-shadow: 2px 1px wheat;
  line-height: 2.8rem;
  font-size: 1.6rem;
  font-weight: 900;
}
.TechSerCard .Description {
  line-height: 1.2rem;
  font-size: 1rem;
  font-weight: 900;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
}
.TechSerCard .SubDescription {
  /* line-height: 1.05rem;
    font-weight: 500;
    font-size: 0.95rem; */
  margin-top: 10px;
  line-height: 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;

  height: -moz-fit-content;

  height: fit-content;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
}
.TechSerCard .PageLink {
  position: absolute;
  bottom: 20px;
  left: -10px;
}
@media screen and (max-width: 1100px) {
  .TechSerCard {
    height: 400px;
    width: 400px;
    padding: 10px;
    margin: 3px;
    margin-top: 90px;
    border-radius: 6px;
  }
  .TechSerCard .IconCircle {
    height: 100px;
    width: 100px;
    border-radius: 60px;
    top: -50px;
    left: 145px;
    padding: 10px;
  }
  .TechSerCard .IconCircle p {
    height: 80px;
    width: 80px;
    border-radius: 40px;
  }
  .TechSerCard .CardTitle {
    position: relative;
    text-align: center;
    top: -100px;
    left: -10px;
    line-height: 2.4rem;
    font-size: 1.6rem;
  }
  .TechSerCard .Description {
    margin-top: 0.4rem;
    line-height: 1.15rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .TechSerCard .SubDescription {
    margin-top: 8px;
    line-height: 1.1rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 450px) {
  .TechSerCard {
    height: 4 50px;
    width: 350px;
    padding: 5px;
    margin: 3px;
    margin-top: 80px;
    border-radius: 6px;
  }
  .TechSerCard .IconCircle {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    top: -40px;
    left: 135px;
  }
  .TechSerCard .IconCircle p {
    height: 60px;
    width: 60px;
  }
  .TechSerCard .CardTitle {
    position: relative;
    text-align: center;
    top: -80px;
    left: 0px;
    line-height: 2.4rem;
    font-size: 1.3rem;
  }
  .TechSerCard .Description {
    margin-top: 0.4rem;
    line-height: 1.15rem;
    font-size: 0.8rem;
    font-weight: 600;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
  }
  .TechSerCard .SubDescription {
    margin-top: 8px;
    line-height: 1.1rem;
    font-size: 0.8rem;
  }
}

