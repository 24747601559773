.aboutUs {
  color: rgb(12, 10, 10);
  display: flex;
  width: 100%;
  flex-direction: column;
}
.aboutUs .CompanyOverview {
  width: 100%;
  margin-top: 0;
}
.aboutUs .WeBeliveIn {
  font-family: "Montserrat", sans-serif;
  position: relative;
  top: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  color: #313131;
  font-size: 1.4rem;
}
.aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
  padding-left: 8rem;
  margin-top: 2rem;
  /* padding-bottom: 3rem; */
  font-size: 1.8rem;
}

.aboutUs .WeBeliveIn .iconList {
  display: grid;
  /* grid-template-columns: repeat(2, 48%); */
  grid-row-gap: 10px;
  color: #0f7fff;
  font-size: 1.8rem;
}
.aboutUs .WeBeliveIn .iconList li img {
  height: 150px;
  width: 150px;
}
.aboutUs .WeBeliveIn .iconList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  list-style: none;
  color: #383838;
  margin-left: 0.6rem;
  margin: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.aboutUs .WeBeliveIn .iconList li span {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.aboutUs .WeBeliveIn .iconList li span .Special_wordIn {
  color: red;
  text-decoration: underline;
}
.aboutUs .WeBeliveIn h4 {
  position: relative;
  color: #083981;
  top: -1%;
  font-size: 2.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;
}

@media screen and (max-width: 1100px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1.2rem;
  }
  .aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
    padding-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .aboutUs .WeBeliveIn .iconList {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li span {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .AboutUs_WeBeliveIn {
    padding-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .aboutUs .WeBeliveIn .iconList {
    font-size: 1rem;
  }
  .aboutUs .WeBeliveIn .iconList li img {
    height: 90px;
    width: 90px;
  }
  .aboutUs .WeBeliveIn .iconList li {
    margin: 0rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .aboutUs {
    font-size: 1rem;
    height: max-content;
  }
  .aboutUs .WeBeliveIn {
    font-size: 1.2rem;
  }
  .aboutUs .WeBeliveIn .iconList li img {
    height: 70px;
    width: 70px;
  }
  .aboutUs .WeBeliveIn .iconList li {
    margin-left: 0rem;
    margin: 0.5rem;
    font-size: 0.8rem;
  }
  .aboutUs .WeBeliveIn .iconList li span {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
