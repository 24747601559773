.leadershipTeam .leadershipTeamPage {
  margin: 1rem 0rem;
  /* padding: 2rem; */
  background-color: #fff;
  width: 100%;
  height: fit-content;
}
.leadershipTeam .leadershipTeamPage .HHeading {
  text-align: left;
  font-size: 1.8rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.leadershipTeam .leadershipTeamPage .TeamMain {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  width: max-content;
  /* margin: 0 5%; */
}
.leadershipTeam .leadershipTeamPage .SecondaryTeam {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
}
.leadershipTeam .leadershipTeamPage .ThirdTeam {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
}
.leadershipTeam .leadershipTeamPage .SecondaryTeam .TeamSecond {
  display: flex;
  justify-content: space-around;
  /* width: 50%; */

  margin: 0 3rem;
}
.leadershipTeam .leadershipTeamPage .ThirdTeam .TeamThird {
  display: flex;
  justify-content: space-around;
  /* width: 50%; */

  margin: 0 3rem;
}
.leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
  background: white;
  padding: 1rem 1rem;
  width: 400px;
  height: 280px;
  /* border: 2px solid rgb(0, 106, 155); */
  /* box-shadow: rgb(66, 66, 66) 2px; */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  position: relative;
  margin: 0 1rem;
}
.TeamMainPersonDetails:hover .FrontSideCard {
  /* transition: transform 0.2s ease; */
  transform: scale(0.25);
  background-color: rgb(209, 209, 209);
  position: relative;
  top: -37%;
  left: -37%;
  border-radius: 50%;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .FrontSideCard {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  background: rgb(253, 253, 253);
  height: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: 1rem;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard {
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard
  .TeamMainPersonNAME {
  color: rgb(0, 106, 155);
  margin-left: 25%;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .BackSideCard
  .SpecialWords {
  text-align: justify;
  overflow-y: auto;
  height: 200px;
  font-size: 0.8rem;
  margin: 1rem;
  margin-top: 1.8rem;
}
.leadershipTeam
  .leadershipTeamPage
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails {
  text-align: center;
  margin: 0 1rem;
}
.leadershipTeam .ThirdTeam .TeamThird .TeamThirdPersonDetails {
  width: 250px;
  text-align: center;
  margin: 0 1rem;
}
.leadershipTeam .ThirdTeam .TeamThird .TeamThirdPersonDetails .TeamFourth {
  width: 120px;
  align-items: center;
  margin-top: 1rem;
  font-size: 12px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamFourth
  .TeamFourthPerson {
  font-size: 12px;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonIMG {
  border-radius: 50%;

  width: 220px;
  height: 200px;
}
.leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonIMG {
  width: 160px;
  height: 140px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonIMG {
  width: 80px;
  height: 80px;
}
/* .leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonIMG {
  width: 160px;
  height: 140px;
} */
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonNAME {
  font-weight: 600;
  font-size: 1.2rem;
}
.leadershipTeam
  .SecondaryTeam
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonNAME {
  font-weight: 600;
  font-size: 16px;
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonNAME {
  font-weight: 600;
  width: 150px;
  font-size: 14px;
}
.leadershipTeam
  .leadershipTeamPage
  .TeamMain
  .TeamMainPersonDetails
  .TeamMainPersonOISITION {
  font-size: 17px;
  color: rgb(75, 74, 74);
}
.leadershipTeam
  .leadershipTeamPage
  .TeamSecond
  .TeamSecondPersonDetails
  .TeamSecondPersonOISITION {
  font-size: 16px;
  color: rgb(75, 74, 74);
}
.leadershipTeam
  .ThirdTeam
  .TeamThird
  .TeamThirdPersonDetails
  .TeamThirdPersonOISITION {
  height: 35px;
  font-size: 12px;
  color: rgb(75, 74, 74);
}

@media screen and (max-width: 1100px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    flex-wrap: wrap;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.4rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.5rem 0.5rem;
    width: 250px;
    height: 230px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    margin: 0 0.5rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 140px;
    font-size: 0.7rem;
    margin: 0.3rem;
    margin-top: 1.5rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 14px;
    text-align: center;
    color: rgb(75, 74, 74);
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    text-align: center;

    font-size: 1rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 160px;
    height: 160px;
  }
}
@media screen and (max-width: 780px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.6rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.2rem 0.2rem;
    width: 160px;
    height: 160px;
    border-radius: 0.2rem;
    margin: 0 0.2rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
    font-size: 10px;
    margin-top: -0.2rem;

  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 100px;
    font-size: 0.5rem;
    margin: 0.1rem;
    margin-top: 0.95rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 8px;
    color: rgb(75, 74, 74);
    text-align: center;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    font-size: 8px;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 110px;
    height: 110px;
  }
}
@media screen and (max-width: 450px) {
  .leadershipTeam .leadershipTeamPage .TeamMain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  .leadershipTeam .leadershipTeamPage .HHeading {
    text-align: left;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
  }
  .leadershipTeam .leadershipTeamPage .TeamMain .TeamMainPersonDetails {
    padding: 0.2rem 0.2rem;
    width: 160px;
    height: 160px;
    border-radius: 0.2rem;
    margin: 0.5rem 0.2rem;
  }
  .TeamMainPersonDetails:hover .FrontSideCard {
    transform: scale(0.25);
    background-color: rgb(209, 209, 209);
    position: relative;
    top: -37%;
    left: -37%;
    border-radius: 50%;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .TeamMainPersonNAME {
    color: rgb(0, 106, 155);
    margin-left: 25%;
    font-size: 10px;
    margin-top: -0.2rem;

  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .BackSideCard
    .SpecialWords {
    height: 100px;
    font-size: 0.5rem;
    margin: 0.1rem;
    margin-top: 0.95rem;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonOISITION {
    font-size: 8px;
    color: rgb(75, 74, 74);
    text-align: center;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonNAME {
    font-weight: 600;
    font-size: 8px;
  }
  .leadershipTeam
    .leadershipTeamPage
    .TeamMain
    .TeamMainPersonDetails
    .TeamMainPersonIMG {
    border-radius: 50%;

    width: 110px;
    height: 110px;
  }
}
