.careers .CareersPage {
  background-color: #fff;
  width: 100%;
  height: max-content;
}
.careers .CareersPage .CareersBG {
  background-image: url("../../Image/career-banner.jpg");
  animation: 1.2s ease-out 0s 1 scaleOnPoint;
  background-size: 90vw 30vw;
  background-position: top;
  background-repeat: no-repeat;
  width: 90%;
  margin: 1rem 5%;
  height: 25vw;
}
.careers .CareersPage .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.careers .CareersPage .Discription p span {
  font-weight: 750;
  font-size: 1.4rem;
  font-style: bold;
}
.careers .CareersPage .CareersBG .HHeading {
  font-size: 7rem;
  font-weight: 800;
  color: rgb(255, 255, 255);
  font-family: "open sans";
  background: transparent;
  padding: 2rem;
}
.careers .CareersPage .JobSection {
  border: 2px solid #ff950f;
  border-radius: 0.5rem;
  background-color: rgba(255, 159, 15, 0.3);
  padding: 1rem;
  margin: 2rem 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobSection ul {
  width: 60%;
  list-style: none;
  text-decoration: underline;
  text-decoration-color: #ffb30f;
  text-decoration-style: solid;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: space-between;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobOffers {
  margin: 2rem 5vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  color: rgb(58, 55, 55);
  font-family: "open sans";
}
.careers .CareersPage .JobOffers .JobOffer_Details {
  /* width: 668px; */
  height: 180px;
  overflow-y: hidden;
  padding: 1rem;
  padding-left: 1.5rem;
  border: 1px solid rgb(119, 115, 115);
  border-left: 5px solid #0fa7ff;
  border-radius: 0.5rem;
  display: inline-block;
  position: relative;
}
.careers .CareersPage .JobOffers .JobOffer_Details.viewMore {
  /* background-color: green; */
  width: 100%;
  height: fit-content !important;
  padding: 1rem;
  padding-left: 1.5rem;
  border: 1px solid rgb(119, 115, 115);
  border-left: 5px solid #0fa7ff;
  border-radius: 0.5rem;
  display: inline-block;
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
  font-size: 1.6rem;
}

.careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements,
.careers .CareersPage .JobOffers .JobOffer_Details .TechnicalReq {
  position: absolute;
  left: 150px;
  width: 30%;
  margin-left: 1.2rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: rgb(109, 108, 105);
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements li,
.careers .CareersPage .JobOffers .JobOffer_Details .TechnicalReq li {
  width: max-content;
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Location {
  font-size: 0.8rem;
  color: rgb(70, 66, 66);
}
.careers .CareersPage .JobOffers .JobOffer_Details .Job_Location i {
  position: relative;
  top: 0.3rem;
  color: rgb(55, 171, 238);
}
.careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
  padding: 0 1rem;
  margin-top: 0.8rem;
  font-size: 1rem;
  color: rgb(70, 66, 66);
}
.sec__heading {
  margin-top: 2.2rem;
  font-size: 1.1rem;
}
.careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription p {
  margin-top: 1rem;
}
.careers
  .CareersPage
  .JobOffers
  .JobOffer_Details
  .JobDiscription
  .ViewMoreApplyHereBTN
  a {
  margin-top: 1rem;
  /* display: block; */
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid black;
  font-size: 1rem;
  text-decoration: none;
  background-color: #2272ce;
  color: rgb(255, 255, 255);
}
.careers
  .CareersPage
  .JobOffers
  .JobOffer_Details
  .JobDiscription
  .ViewMoreApplyHereBTN
  a:hover {
  background-color: #0362ce;
  transform: scale(0.98);
  cursor: pointer;
}

.careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
  font-family: "Montserrat", sans-serif;

  position: relative;
  /* bottom: 0%; */
  /* top   : 10%; */
  /* right: -500px; */
  background-color: rgb(226, 226, 226);
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
  font-size: 1rem;
  color: rgb(70, 66, 66);
}
@media screen and (max-width: 1100px) {
  .careers .CareersPage .CareersBG {
    background-size: 90vw 30vw;
    width: 100%;
    margin: 0 0;
    height: 25vw;
  }
  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 1.4rem;
  }
  .careers .CareersPage .Discription p {
    width: 100%;
    line-height: 2.5rem;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.5rem 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 780px) {
  .careers .CareersPage {
    min-height: 90vh;
    height: fit-content;
  }

  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .careers .CareersPage .Discription p {
    line-height: 1.8rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1.2rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements {
    position: absolute;
    left: 130px;
    width: 30%;
    margin-left: 0.1rem;
    font-size: 0.9rem;
  }

  .careers .CareersPage .JobOffers .JobOffer_Details {
    height: 120px;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-left: 0.8rem;
    position: relative;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
    padding: 0 1rem;
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
    position: relative;
    top: -10px;
    padding: 0.2rem 0.4rem;
    border-radius: 0.8rem;
    font-size: 0.8rem;
    color: rgb(70, 66, 66);
  }
  /* .careers
    .CareersPage
    .JobOffers
    .JobOffer_Details
    .JobDiscription
    .ViewMoreApplyHereBTN
    a {
    margin-top: 1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.8rem;
    font-size: 0.8rem;
  } */
}
@media screen and (max-width: 450px) {
  .careers .CareersPage .Discription p span {
    font-weight: 600;
    font-size: 0.8rem;
  }
  .careers .CareersPage .Discription p {
    text-align: justify;
    line-height: 1.2rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.6rem;
  }
  .careers .CareersPage .JobOffers {
    margin: 0.2rem 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Title {
    font-size: 1rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .Job_Requirements {
    position: absolute;
    left: 110px;
    width: 30%;
    margin-left: 0.1rem;
    margin-top: 5px;
    font-size: 0.8rem;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details {
    height: 115px;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-left: 0.8rem;
    position: relative;
  }
  .careers .CareersPage .JobOffers .JobOffer_Details .JobDiscription {
    padding: 0 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
  /* .careers
    .CareersPage
    .JobOffers
    .JobOffer_Details
    .JobDiscription
    .ViewMoreApplyHereBTN
    a {
    margin-top: 1rem;
    padding: 0.1rem 0.2rem;
    border-radius: 0.5rem;
    font-size: 0.6rem;
  } */
  .careers .CareersPage .JobOffers .JobOffer_Details .JDApplyHereForm {
    padding: 0.2rem 0.3rem;
    border-radius: 0.8rem;
    font-size: 0.6rem;
  }
}
