.ContactUs .ContactUsBG {
  position: relative;
  top: 80px;
  background-image: url("../../Image/contact3.jpg");
  background-size: 100vw 60vh;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 40vh;
  margin-bottom: 2rem;
}
.ContactUsMain {
  display: flex;
  flex-direction: row;
}
.ContactUsMain .CompanyDetails {
  padding: 6rem 3rem 1.5rem;
  color: rgb(54, 52, 52);
  border-radius: 2rem;
  position: relative;
  top: 26%;
  left: 5%;
  height: 700px;
  width: 40%;
  margin-right: 10%;
}

.CompanyDetails h3 {
  font-family: "Audiowide", sans-serif;
  font-weight: 300;
  color: rgb(2, 2, 97);
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

.CompanyDetails p {
  width: 140%;
  font-family: "Montserrat", sans-serif;

  font-weight: 300;
  color: rgb(51, 51, 51);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.Special_In {
  color: red;
  text-decoration: none;
}
.CompanyDetails .ContactDetailsUL {
  display: grid;
  grid-template-columns: 30% 68%;
  row-gap: 0.8rem;
  column-gap: 0.8rem;
  padding-left: 0.2rem;
}
.CompanyDetails .Contact {
  font-family: "Montserrat", sans-serif;

  list-style: none;
  line-height: 2.4rem;
  font-size: 16px;
  font-weight: 400;
}
.ContactDetailsUL a {
  text-decoration: none;
}
.ContactDetailsUL a button {
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2988be;
  color: #f0f0f0;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  font-size: 14px;
}
.ContactDetailsUL a button:hover {
  background-color: #0e679b;
  color: #ffffff;
  cursor: pointer;
  transform: scale(0.98);
}
.ContactDetailsUL a .GetDirectionsBTN {
  position: relative;
  top: 20%;
  height: 50%;
}

.Contact.WhatsApp i {
  color: rgb(22, 150, 86);
  position: relative;
  top: 0.35rem;
}

@media screen and (max-width: 1100px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 80px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain .CompanyDetails {
    padding: 6rem 1.5rem 1.5rem 2rem;
    top: 26%;
    left: 0;
    height: fit-content;
    width: 40%;
    margin-right: 10%;
    margin-bottom: 10%;
  }

  .CompanyDetails h3 {
    font-size: 1.5rem;
  }
  .CompanyDetails p {
    width: 100%;
    font-size: 1rem;
  }

  .CompanyDetails .ContactDetailsUL {
    width: 120%;
    display: grid;
    grid-template-columns: 26% 82%;
    row-gap: 0.8rem;
    column-gap: 0.8rem;
    padding-left: 0.2rem;
  }
}
@media screen and (max-width: 780px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 80px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain {
    flex-direction: column;
  }
  .CompanyDetails h3 {
    font-size: 1.2rem;
  }
  .CompanyDetails p {
    font-size: 1rem;
  }

  .ContactUsMain .CompanyDetails {
    padding: 6rem 3rem 1.5rem;
    color: rgb(54, 52, 52);
    border-radius: 2rem;
    position: relative;
    top: 26%;
    left: 0;
    height: fit-content;
    width: 100%;
  }
  .CompanyDetails .ContactDetailsUL {
    width: 90%;
    display: grid;
    grid-template-columns: 26% 65%;
    row-gap: 0.8rem;
    column-gap: 0.8rem;
    padding-left: 0.2rem;
  }
  .CompanyDetails .Contact {
    line-height: 2rem;
    font-size: 14px;
    font-weight: 400;
  }
  .ContactDetailsUL a button {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .ContactUs .ContactUsBG {
    position: relative;
    top: 50px;
    background-size: 100vw 30vw;
    width: 100%;
    height: 30vw;
    margin-bottom: 2rem;
  }
  .ContactUsMain {
    flex-direction: column;
  }
  .ContactUsMain .CompanyDetails {
    padding: 3rem 2rem 1.5rem;
    border-radius: 2rem;
    top: 22%;
  }
  .CompanyDetails h3 {
    font-size: 1rem;
  }
  .CompanyDetails p {
    font-size: 0.8rem;
  }
  .CompanyDetails .Contact {
    font-size: 12px;
  }
  .ContactDetailsUL a button {
    font-size: 10px;
  }
}
