.mission {
  width: 100%;
}
.MissionPage {
  height: fit-content;
  width: 100%;
}
.MissionPage .MissionVision {
  display: grid;
  grid-template-columns: 1fr 220px 1fr;
  width: 100%;
  height: 280px;
}
.MissionPage .MissionVisionImg {
  height: 190px;
}
.MissionPage .MissionVisionImg img {
  position: relative;
  right: 50px;
  margin-top: 4rem;
  width: 250px;
}
.Special_wordIn {
  color: red;
  text-decoration: underline;
}
.mission .MissionPage .VisionSec {
  margin-top: 3rem;
  position: relative;
  margin-left: 2rem;
}
.mission .MissionPage .MissionSec {
  margin-top: 3rem;
  margin-left: 5rem;
}
.mission .MissionPage .MissionSec .heading .newFontTagline {
  color: #1490b6;
  font-size: 1.2rem;
}
.mission .MissionPage .VisionSec .heading .VisionFirstLine {
  color: #e48100;
  font-size: 0.95rem;
}
.mission .MissionPage .MissionSec .heading,
.mission .MissionPage .VisionSec .heading {
  padding-top: 0.4rem;
  font-size: 0.9rem;
  line-height: 2.5rem;
  width: 100%;
}
.mission .MissionPage .MissionSec .HHeading,
.mission .MissionPage .VisionSec .HHeading {
  margin-top: 2.5rem;
  font-size: 1.6rem;
  color: rgb(51, 51, 51);
}
/* .Mission_WhyWeAre p {
  margin: 1rem;

  font-family: "Open Sans", sans-serif;
  line-height: 2.5rem;

  font-size: 16px;
} */
.CoreValuesSec {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.CoreValuesSec .CoreValuesBottomTagLine {
  text-align: center;
  margin: 2rem;
  font-size: 1.5rem;
}
.CoreValuesSec .HHeading {
  color: #313131;
  font-size: 1.8rem;
  padding-left: 5rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
}
.CoreValuesSec .CoreValuesTagLine {
  text-align: center;
  color: #00529e;
  font-size: 1.8rem;
  padding-left: 5rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
}

/* .CoreValuesSec .CoreValues .HHeading {
  position: relative;
  top: -2%;
  height: unset;
} */
/* .CoreValuesSec .CoreValues .heading {
  height: unset;
  line-height: 1.8;
  width: 100%;
} */
.CoreValuesSec .CoreValuesImg {
  display: flex;
  justify-content: space-evenly;
}
.CoreValuesSec .CoreValuesImg .Ourvalue {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}
.CoreValuesSec .CoreValuesImg .Ourvalue img {
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
}
.CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
  text-align: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 1100px) {
  .MissionPage .MissionVision {
    display: grid;
    grid-template-columns: 1fr 180px 1fr;
    width: 100%;
  }
  .MissionPage .MissionVisionImg {
    height: 180px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    width: 210px;
  }
  .mission .MissionPage .VisionSec {
    align-items: center;
    margin-left: 2rem;
  }
  .mission .MissionPage .MissionSec {
    align-items: center;
    margin-left: 4rem;
    /* margin-left: 4vw; */
  }
  .mission .MissionPage .MissionSec .HHeading,
  .mission .MissionPage .VisionSec .HHeading {
    font-size: 1.2rem;
  }
  .mission .MissionPage .MissionSec .heading .newFontTagline {
    font-size: 0.9rem;
  }
  .mission .MissionPage .VisionSec .heading .VisionFirstLine {
    font-size: 0.7rem;
  }
  .mission .MissionPage .MissionSec .heading,
  .mission .MissionPage .VisionSec .heading {
    font-size: 0.67rem;
    line-height: 1.5rem;
    width: 100%;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .HHeading {
    font-size: 1.2rem;
    padding-left: 2rem;
    margin-right: 0rem;
    padding-bottom: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 1rem;
    padding-left: 2rem;
    padding-bottom: 1.5rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 1rem;
  }

  .CoreValuesSec .CoreValues .heading {
    height: unset;
    line-height: 1.8;
    width: 100%;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 2rem;
    width: 50px;
    height: 50px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    text-align: center;
    font-size: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .MissionPage .MissionVision {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  .MissionPage .MissionVisionImg {
    height: 100px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    top: -70px;
    width: 180px;
  }
  .mission .MissionPage .VisionSec {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  .mission .MissionPage .MissionSec {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 0.8rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 0.8rem;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    width: fit-content;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 2rem;
    width: 40px;
    height: 40px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    text-align: center;
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 450px) {
  .MissionPage .MissionVision {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  .MissionPage .MissionVisionImg {
    height: 150px;
  }
  .MissionPage .MissionVisionImg img {
    right: 0;
    width: 210px;
  }
  .mission .MissionPage .VisionSec {
    margin-top: 0;
    margin-left: 0rem;
  }
  .mission .MissionPage .MissionSec {
    margin-left: 0;
  }
  .mission .MissionPage .MissionSec .HHeading,
  .mission .MissionPage .VisionSec .HHeading {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
  .mission .MissionPage .MissionSec .heading .newFontTagline {
    font-size: 0.9rem;
  }
  .mission .MissionPage .VisionSec .heading .VisionFirstLine {
    font-size: 0.7rem;
  }
  .mission .MissionPage .MissionSec .heading,
  .mission .MissionPage .VisionSec .heading {
    font-size: 0.67rem;
    line-height: 1.5rem;
    width: 100%;
  }
  /* corevalues */
  .CoreValuesSec {
    margin-top: 1rem;
  }
  .CoreValuesSec .CoreValuesImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .CoreValuesSec .HHeading {
    font-size: 1.2rem;
    padding-left: 2rem;
    margin-right: 0rem;
    padding-bottom: 1rem;
  }
  .CoreValuesSec .CoreValuesTagLine {
    font-size: 1rem;
    padding-left: 2rem;
    padding-bottom: 1.5rem;
  }
  .CoreValuesSec .CoreValuesBottomTagLine {
    margin: 0.5rem;
    font-size: 1rem;
  }

  .CoreValuesSec .CoreValues .heading {
    height: fit-content;
    line-height: 1.8;
    width: 100%;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue img {
    margin-bottom: 0rem;
    width: 50px;
    height: 50px;
  }
  .CoreValuesSec .CoreValuesImg .Ourvalue .ourValueName {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 0.9rem;
  }
}
